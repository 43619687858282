import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { query, getRelationship } from 'redux-bees'
import { Link } from 'react-router-dom'

import { login, flashMessage } from 'actions'
import { isLoggedIn, getAuth } from 'selectors'
import { Layout, HealthForm, FormContainer, Notice } from 'components'
import { ROUTES } from '../../constants'
import api from 'api'
import { jsonapiToFormErrors, urlFor, dashToUnderscoreObject } from 'utils'

const mapState = state => ({
  authInfo: getAuth(state),
  isLoggedIn: isLoggedIn(state),
  signature: form => getRelationship(state, form, 'primary-signature'),
  getTravelSignature: form => getRelationship(state, form, 'travel-signature'),
  getWitnessSignature: form => getRelationship(state, form, 'witness-signature'),
  getCopiedFrom: form => getRelationship(state, form, 'copied-from'),
  getInitials: form => getRelationship(state, form, 'initials'),
  registration: healthForm => getRelationship(state, healthForm, 'registration'),
  areaCamp: registration => getRelationship(state, registration, 'area-camp'),
  ylCamp: areaCamp => getRelationship(state, areaCamp, 'yl-camp'),
})

const mapDispatch = {
  login: login,
  flash: flashMessage,
}

export default compose(
  connect(mapState, mapDispatch),
  query('healthForm', api.getHealthForm, (perform, { match: { params: { id } } }) =>
    perform({
      id: id,
      'include[]':
        'primary_signature,travel_signature,witness_signature,signatures,registration.area_camp.yl_camp,copied_from,initials',
    })
  )
)(
  class EditHealthForm extends Component {
    onSubmit(valuesToSubmit, { setSubmitting, setStatus, setFieldValue, values }) {
      const { healthForm } = this.props
      const { primary_signature_attributes, ...restValues } = valuesToSubmit
      api
        .updateHealthForm(
          { id: healthForm.id },
          {
            health_form: {
              ...restValues,
              ...(primary_signature_attributes.text &&
                primary_signature_attributes.text.length > 0 && { primary_signature_attributes }),
            },
          }
        )
        .then(() => {
          this.props.flash('Saved')
        })
        .catch(err => {
          jsonapiToFormErrors(values, err, setStatus)
        })
        .finally(() => setSubmitting(false))
    }
    onConfirm(values, { setSubmitting, setStatus }) {
      const { healthForm, registration, areaCamp } = this.props
      api
        .confirmHealthForm({ id: this.props.match.params.id }, { health_form: { ...values } })
        .then(response => {
          const next = () => {
            if (parseFloat(areaCamp(registration(healthForm)).attributes['deposit']) > 0) {
              const token = registration(healthForm).attributes['token']
              return `${urlFor(ROUTES.payments.new, {
                id: token,
              })}?deposit=${areaCamp(registration(healthForm)).attributes['deposit']}`
            } else {
              return urlFor(ROUTES.dashboard, { tab: 'campers' })
            }
          }
          this.props.flash('Confirmed')
          this.props.history.push(next())
        })
        .catch(err => {
          jsonapiToFormErrors(values, err, setStatus)
        })
        .finally(() => setSubmitting(false))
    }
    render() {
      const {
        healthForm,
        signature,
        ylCamp,
        registration,
        areaCamp,
        getCopiedFrom,
        getTravelSignature,
        getWitnessSignature,
        getInitials,
      } = this.props
      const primarySignature = signature(healthForm)
      const travelSignature = getTravelSignature(healthForm)
      const witnessSignature = getWitnessSignature(healthForm)
      const copiedFrom = getCopiedFrom(healthForm)
      const initials = getInitials(healthForm)
      const email = this.props.authInfo.email
      return (
        <Layout>
          <FormContainer
            before={
              copiedFrom &&
              healthForm.attributes.status === 'Incomplete' && (
                <Notice>
                  <p>
                    We have pre-filled the fields below using your last submission for{' '}
                    {healthForm.attributes['first-name']} at {healthForm.attributes['area-name']}, please review that
                    the information is correct, and sign on the last page. If nothing has changed, feel free to{' '}
                    <Link to="?page=5">go straight to the signature page.</Link>
                  </p>
                </Notice>
              )
            }
          >
            {healthForm && (
              <HealthForm
                validateOnPageChange={true}
                healthForm={healthForm}
                primarySignature={
                  primarySignature && {
                    id: primarySignature.id,
                    ...dashToUnderscoreObject(primarySignature.attributes),
                  }
                }
                travelSignature={
                  travelSignature && {
                    id: travelSignature.id,
                    ...dashToUnderscoreObject(travelSignature.attributes),
                  }
                }
                travelWitnessSignature={
                  witnessSignature && {
                    id: witnessSignature.id,
                    ...dashToUnderscoreObject(witnessSignature.attributes),
                  }
                }
                initials={
                  initials &&
                  initials.reduce((initialsObject, initial) => {
                    initialsObject[initial.attributes['initial-type']] = {
                      id: initial.id,
                      ...dashToUnderscoreObject(initial.attributes),
                    }
                    return initialsObject
                  }, {})
                }
                email={email}
                ylCamp={ylCamp(areaCamp(registration(healthForm)))}
                onConfirm={(...args) => this.onConfirm(...args)}
                onSubmit={(...args) => this.onSubmit(...args)}
                history={this.props.history}
              />
            )}
          </FormContainer>
        </Layout>
      )
    }
  }
)
