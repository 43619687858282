import React, { Fragment } from 'react'
import { object, string } from 'yup'
import { Link } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { subYears } from 'date-fns'
import { Input } from 'components'
import { ROUTES } from '../../constants'
import {
  getGendersForSelect,
  getProvincesForSelect,
  getShirtSizesForSelect,
  formatDate,
  isLeaders,
  urlFor,
} from 'utils'

const newValidationSchema = object().shape({
  address: string().required(),
  city: string().required(),
  dob: string().required(),
  first_name: string().required(),
  gender: string().required(),
  last_name: string().required(),
  parents_email: string().required(),
  parents_email_confirmation: string().required(),
  phone: string().required(),
  postal_code: string().required(),
  prov: string().required(),
})

const editValidationSchema = object().shape({
  address: string().required(),
  city: string().required(),
  dob: string().required(),
  first_name: string().required(),
  gender: string().required(),
  last_name: string().required(),
  parents_email: string().required(),
  phone: string().required(),
  postal_code: string().required(),
  prov: string().required(),
})

const CamperForm = ({ camper, onSubmit }) => (
  <Formik
    initialValues={{
      first_name: camper ? camper.attributes['first-name'] : '',
      last_name: camper ? camper.attributes['last-name'] : '',
      dob: camper ? formatDate(camper.attributes['dob'], 'yyyy-MM-dd') : '',
      gender: camper ? camper.attributes['gender'] : '',
      shirt_size: camper ? camper.attributes['shirt-size'] : '',
      address: camper ? camper.attributes['address'] : '',
      city: camper ? camper.attributes['city'] : '',
      prov: camper ? camper.attributes['prov'] : '',
      postal_code: camper ? camper.attributes['postal-code'] : '',
      phone: camper ? camper.attributes['phone'] : '',
      school: camper ? camper.attributes['school'] : '',
      grade: camper ? camper.attributes['grade'] : '',
      parents_name: camper ? camper.attributes['parents-name'] : '',
      parents_email: camper ? camper.attributes['parents-email'] : '',
    }}
    validationSchema={camper ? editValidationSchema : newValidationSchema}
    onSubmit={(values, formOptions) => {
      onSubmit(values, formOptions)
    }}
    render={({ status, values, errors }) => {
      return (
        <Form className="form">
          <fieldset>
            <legend>{isLeaders ? 'Leader' : 'Camper'}'s Information</legend>
            <div className="form__group">
              <Field component={Input} name="first_name" label="First Name" />
              <Field component={Input} name="last_name" label="Last Name" />
              <Field
                component={Input}
                type="date"
                name="dob"
                label="Birth Date"
                maxDate={new Date()}
                minDate={subYears(new Date(), 100)}
                openToDate={subYears(new Date(), 10)}
              />
              <Field component={Input} type="select" name="gender" label="Gender" options={getGendersForSelect()} />
              <Field
                component={Input}
                type="select"
                name="shirt_size"
                label="Shirt Size"
                options={getShirtSizesForSelect()}
              />
              <Field component={Input} name="address" label="Address" />
              <Field component={Input} name="city" label="City" />
              <Field component={Input} type="select" name="prov" label="Province" options={getProvincesForSelect()} />
              <Field component={Input} name="postal_code" label="Postal Code" />
              {isLeaders ? (
                <Fragment>
                  <Field component={Input} name="phone" label="Phone" />
                  <Field component={Input} name="parents_email" label="Email" />
                  {!camper && <Field component={Input} name="parents_email_confirmation" label="Confirm Email" />}
                </Fragment>
              ) : (
                <Fragment>
                  <Field component={Input} name="phone" label="Parent Phone" />
                  <Field component={Input} name="school" label="School" />
                  <Field component={Input} name="grade" label="Grade" />
                </Fragment>
              )}
            </div>
          </fieldset>
          {!isLeaders && (
            <fieldset>
              <legend>Parent's Information</legend>
              <Field component={Input} name="parents_name" label="Parent's Name" />
              <Field component={Input} name="parents_email" label="Parent's Email" />
              {!camper && <Field component={Input} name="parents_email_confirmation" label="Confirm Parent's Email" />}
            </fieldset>
          )}
          {status && status.apiErrors['base'] && <div className="errors--base">{status.apiErrors['base']}</div>}
          <button type="submit">Save</button>
          <Link to={urlFor(ROUTES.dashboard, { tab: 'campers' })}>Cancel</Link>
        </Form>
      )
    }}
  />
)

export default CamperForm
