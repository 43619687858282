import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { Layout } from 'components'
import { query } from 'redux-bees'
import api from 'api'
import { ROUTES } from '../../../constants'
import { urlFor } from 'utils'

export default compose(query('healthFormLandingMessage', api.getHealthFormLandingMessage))(
  class Landing extends Component {
    render() {
      const { healthFormLandingMessage } = this.props
      return (
        <Layout>
          <div className="container">
            <h2>Welcome to our Health Information Form system</h2>
            {healthFormLandingMessage && healthFormLandingMessage.attributes.setting}
            <p>Please select the form most relevant to your trip below:</p>
            <table className="table-minimal table-minimal--wrap-mobile" style={{ maxWidth: '600px' }}>
              <tbody>
                <tr>
                  <td>Week-long camps within the country</td>
                  <td style={{ textAlign: 'right' }}>
                    <Link
                      to={urlFor(ROUTES.public.healthForms.new, { type: 'rrc' })}
                      className="btn sm"
                      style={{ marginLeft: '5px' }}
                    >
                      Fill
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>Trips to the US</td>
                  <td style={{ textAlign: 'right' }}>
                    <Link
                      to={urlFor(ROUTES.public.healthForms.new, { type: 'out-of-country' })}
                      className="btn sm"
                      style={{ marginLeft: '5px' }}
                    >
                      Fill
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>Day-trips</td>
                  <td style={{ textAlign: 'right' }}>
                    <Link
                      to={urlFor(ROUTES.public.healthForms.new, { type: 'weekend' })}
                      className="btn sm"
                      style={{ marginLeft: '5px' }}
                    >
                      Fill
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Layout>
      )
    }
  }
)
