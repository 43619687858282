import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import cn from 'classnames'
import { getAuth, isLoggedIn } from 'selectors'
import { logout } from 'actions'
import { urlFor } from '../../utils'
import { ROUTES } from '../../constants'

const mapState = state => ({
  isLoggedIn: isLoggedIn(state),
  authInfo: getAuth(state),
})

const mapDispatch = {
  logout: logout,
}

export default connect(
  mapState,
  mapDispatch
)(
  class Header extends Component {
    state = { navOpen: false }
    toggleNav() {
      this.setState({ navOpen: !this.state.navOpen })
    }
    render() {
      const { authInfo, isLoggedIn, title, background, size } = this.props
      return (
        <header className={cn('header', { [`header--${size}`]: size })}>
          {background && <div className="header__background">{background}</div>}
          <div className="header__wrap">
            <div className="header__content">
              <div className={cn('header__bar', { open: this.state.navOpen })}>
                <a href="https://www.younglife.ca" className="header__logo">
                  <img
                    src="/assets/logo-full-color.svg"
                    className="header__logo--color"
                    alt="YoungLife. You were made for this..."
                  />
                  <img
                    src="/assets/logo-main.svg"
                    className="header__logo--white"
                    alt="YoungLife. You were made for this..."
                  />
                </a>
                <nav className={cn('main-nav', { open: this.state.navOpen })}>
                  <button className="main-nav__toggle mobile_and_tablet_only" onClick={() => this.toggleNav()}>
                    <FontAwesomeIcon icon={['far', 'bars']} />
                  </button>
                  <div className="main-nav__collapse">
                    <ul className="main-nav__account">
                      {isLoggedIn ? (
                        <Fragment>
                          <li>
                            <Link to={ROUTES['profile']}>{authInfo.email}</Link>
                          </li>
                          <li>
                            <Link className="btn" to={urlFor(ROUTES['dashboard'], { tab: 'campers' })}>
                              Dashboard
                            </Link>
                          </li>
                          <li>
                            <Link className="btn" to={ROUTES['help']}>
                              Help
                            </Link>
                          </li>
                          <li>
                            <button className="btn" onClick={this.props.logout}>
                              Logout
                            </button>
                          </li>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <li>
                            <Link className="btn" to={ROUTES['login']}>
                              Login
                            </Link>
                          </li>
                        </Fragment>
                      )}
                    </ul>
                    <ul className="main-nav__items">
                      <li className="main-nav__item">
                        <a href="https://www.younglife.ca/about-us/" className="main-nav__link">
                          About Us
                        </a>
                      </li>
                      <li className="main-nav__item">
                        <a href="https://www.younglife.ca/programs/" className="main-nav__link">
                          Programs
                        </a>
                      </li>
                      <li className="main-nav__item">
                        <a href="https://www.younglife.ca/find-yl/" className="main-nav__link">
                          Find YL Near You
                        </a>
                      </li>
                      <li className="main-nav__item">
                        <Link to="/" className="main-nav__link active">
                          Camp
                        </Link>
                      </li>
                      <li className="main-nav__item">
                        <a href="https://www.younglife.ca/events/" className="main-nav__link">
                          Events
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            <div className="header__title">{title}</div>
          </div>
        </header>
      )
    }
  }
)
