import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default () => (
  <footer id="footer">
    <div id="main-footer">
      <div className="container">
        <div className="wrapper">
          <nav>
            <ul>
              <li>
                <Link to="/">Camp</Link>
              </li>
              <li>
                <a href="https://www.younglife.ca/contact-us/">Contact</a>
              </li>
              <li>
                <a href="https://www.younglife.ca/financials/">Financials</a>
              </li>
              <li>
                <a href="https://www.younglife.ca/programs/">Programs</a>
              </li>
              <li>
                <a href="https://www.younglife.ca/events/">Events</a>
              </li>
              <li>
                <a href="https://www.younglife.ca/faq/">FAQs</a>
              </li>
              <li>
                <a href="https://www.younglife.ca/forms/">Forms</a>
              </li>
            </ul>
          </nav>
          <nav>
            <ul>
              <li>
                <a href="https://www.skatelife.ca">Skatelife</a>
              </li>
              <li>
                <a href="https://capernaum.younglife.ca">Capernaum</a>
              </li>
              <li>
                <a href="https://www.younglife.ca">younglife.ca</a>
              </li>
              <li>
                <a href="https://rockridgecanyon.com">RockRidgeCanyon.com</a>
              </li>
              <li>
                <a href="https://www.facebook.com/younglifecanada/" className="facebook">
                  <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://twitter.com/ylcanada/" className="twitter">
                  <FontAwesomeIcon icon={['fab', 'twitter']} />
                  Twitter
                </a>
              </li>
            </ul>
          </nav>
          <div className="contact">
            Young Life of Canada National Service Centre
            <br />
            <span className="address">
              120, 9440 - 202 St.
              <br />
              Langley, BC <br className="desktop_only" />
              V1M 4A6
            </span>
          </div>
        </div>
      </div>
    </div>
    <div id="second-footer">
      <div className="container">
        <div className="desktop_only">
          <div className="copyright">
            © {new Date().getFullYear()} Young Life of Canada · Registered Canadian Charity 119306918RR0001 ·{' '}
            <a href="https://www.younglife.ca/privacy-policy/">Privacy Policy</a>
          </div>
          <div className="charity">Member of the Canadian Council of Christian Charities</div>
        </div>
        <div className="mobile_and_tablet_only">
          <p>
            <a href="https://www.younglife.ca/privacy-policy/">Privacy Policy</a>
          </p>
          <p>Member of the Canadian Council of Christian Charities</p>
          <p>Registered Canadian Charity 119306918RR0001</p>
          <p>© {new Date().getFullYear()} Young Life of Canada</p>
        </div>
      </div>
    </div>
  </footer>
)
