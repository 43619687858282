import React from 'react'

export default () => (
  <div className="loading-container">
    <div className="loading">
      <p>Loading</p>
      <div className="spinner" />
    </div>
  </div>
)
