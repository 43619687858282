import { format, formatDistanceToNow } from 'date-fns'
import formatcurrency from 'format-currency'
import { get } from 'lodash'
import publicIp from 'public-ip'

export const formatDate = (timestamp, formatString = 'ddd, Ha') => format(new Date(timestamp), formatString)
export const relativeDate = timestamp => formatDistanceToNow(new Date(timestamp))
export const formatCurrency = amount => formatcurrency(amount)
export const formatCamperType = type => {
  switch (type) {
    case 'WL':
      return 'WL (Jr. High)'
    case 'SH':
      return 'SH (Sr. High)'
    case 'EL&SR':
      return 'WL&SR (Jr. and Sr. High)'
    case 'Leader':
      return 'Leader'
    default:
      return type
  }
}
export const isCreditCardPayment = payment => payment.attributes['payment-type'] === 'Credit Card'

export const jsonapiToFormErrors = (values, response, setStatus) => {
  // {"errors":[{"source":{"pointer":"/data/attributes/password-confirmation"},"detail":"doesn't match Password"}]}
  let formErrors = {}

  const errors = get(response, 'body.errors', [])
  if (errors.length === 0) {
    console.log('Invalid error response!', response)
    return {}
  }

  errors.forEach(error => {
    const key =
      error.source.pointer
        .replace(/\/data\/attributes\//, '')
        .replace('-', '_')
        .toLowerCase() || 'base'
    switch (key) {
      case 'province_health_insurance_policy_num':
      case 'province_health_insurance_policy_province':
      case 'foreign_medical_co_contact_number':
      case 'foreign_medical_co_emergency_phone_number':
      case 'foreign_medical_co_name':
      case 'foreign_medical_co_policy_number':
        if (values.health_insurance_choice.length === 0) {
          formErrors['health_insurance_choice'] = 'missing fields'
        }
        formErrors[key] = error.detail
        break
      case 'initials':
        // Pull the initial type from the message
        const [, initialType, message] = error.detail.match(/for ([a-z_]+)(.*)/)
        formErrors[`initials.${initialType}`] = message
        break
      default:
        formErrors[key] = error.detail
        break
    }
  })
  setStatus({ apiErrors: formErrors })

  return formErrors
}

export const dashToUnderscoreObject = dasherizedObject => {
  let underscorizedObject = {}
  Object.keys(dasherizedObject).forEach(key => {
    underscorizedObject[key.replace(/-/g, '_')] = dasherizedObject[key]
  })
  return underscorizedObject
}

export const urlFor = (route, params = {}, querystringParams = {}) => {
  let url = route
  Object.keys(params).forEach(key => {
    url = url.replace(`:${key}`, params[key])
  })

  const querystring = Object.keys(querystringParams)
    .map(key => `${key}=${querystringParams[key]}`)
    .join('&')
  if (querystring && querystring.length > 0) {
    url += `?${querystring}`
  }

  return url
}

export const getGendersForSelect = () => [
  {
    label: 'M',
    value: 'M',
  },
  {
    label: 'F',
    value: 'F',
  },
  {
    label: 'Other',
    value: 'Other',
  },
]

export const getMedicalConditionsForForm = () => [
  'Breathing Issues',
  'Fainting',
  'Behaviour issues',
  'Diabetes',
  'Infections',
  'Operations or serious injury',
  'Head Injury',
  'Seizures',
  'Bleeding condition',
  'Digestive condition',
  'Heart condition',
  'Bone/joint condition',
  'Cancer',
  'ADHD (Hyperactive Disorder)',
  'Mental health concerns',
  'Other conditions not listed',
]

export const getImmunizationsForForm = () => [
  'DPT (Diphtheria/Pertussis/Tetanus)',
  'Polio',
  'MMR (Measles/Mumps/Rubella)',
  'Hep A',
  'Hep B',
  'Influenza',
]

export const getDietOptionsForForm = () => [
  'Gluten',
  'Dairy',
  'Vegetarian',
  'Vegan',
  'Nut',
  'Soy',
  'Egg',
  'Seafood',
  'Other Allergies',
]
export const getProvincesForSelect = () => [
  {
    label: 'AB',
    value: 'AB',
  },
  {
    label: 'BC',
    value: 'BC',
  },
  {
    label: 'MB',
    value: 'MB',
  },
  {
    label: 'NB',
    value: 'NB',
  },
  {
    label: 'NL',
    value: 'NL',
  },
  {
    label: 'NS',
    value: 'NS',
  },
  {
    label: 'NT',
    value: 'NT',
  },
  {
    label: 'NU',
    value: 'NU',
  },
  {
    label: 'ON',
    value: 'ON',
  },
  {
    label: 'PE',
    value: 'PE',
  },
  {
    label: 'QC',
    value: 'QC',
  },
  {
    label: 'SK',
    value: 'SK',
  },
]

export const getShirtSizesForSelect = () => [
  {
    label: 'XS',
    value: 'XS',
  },
  {
    label: 'S',
    value: 'S',
  },
  {
    label: 'M',
    value: 'M',
  },
  {
    label: 'L',
    value: 'L',
  },
  {
    label: 'XL',
    value: 'XL',
  },
]

export const getCreditCardOptionsForSelect = () => [
  {
    label: 'VISA',
    value: 'VISA',
  },
  {
    label: 'MC',
    value: 'MC',
  },
  {
    label: 'AMX',
    value: 'AMX',
  },
  {
    label: 'DSC',
    value: 'DSC',
  },
]
export const isPaid = (paidAmount, totalAmount) => parseFloat(paidAmount) >= parseFloat(totalAmount)
export const toCurrency = amount => `$${amount}`
export const titleize = string => string.replace(/^\w/, c => c.toUpperCase())

export const isLeaders = process.env.REACT_APP_LEADERS === 'true'

export const getPublicIp = async () => {
  if (sessionStorage.getItem('publicIp')) {
    return sessionStorage.getItem('publicIp')
  }

  const ipAddress = await publicIp.v4()
  sessionStorage.setItem('publicIp', ipAddress)
  return ipAddress
}
