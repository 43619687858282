import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'
import { default as ReactModal } from 'react-modal'
import LogRocket from 'logrocket'

import 'config/formValidation'
import { Landing, Login, Dashboard, Profile, ForgotPassword, ResetPassword, Signup } from 'components'
import {
  Help,
  NewRegistration,
  EditRegistration,
  NewCamper,
  EditCamper,
  ViewPayment,
  NewPayment,
  EditHealthForm,
  PaymentSuccess,
  PublicUser,
  PublicHealthFormLanding,
  PublicHealthFormNew,
  PublicHealthFormCreated,
  PublicHealthFormSign,
  PublicHealthFormSignSuccess,
} from 'pages'
import { PrivateRoute } from 'containers'
import { ROUTES } from './constants'
import store from './store'

import 'styles/all.scss'
import * as serviceWorker from './serviceWorker'

const persistor = persistStore(store)

library.add(far, fab)
// library.add(fab)

const appElement = document.getElementById('root')

ReactModal.setAppElement(appElement)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path={ROUTES.help} component={Help} />
          <Route exact path={ROUTES.login} component={Login} />
          <Route exact path={ROUTES.forgotPassword} component={ForgotPassword} />
          <Route exact path={ROUTES.resetPassword} component={ResetPassword} />
          <Route exact path={ROUTES.signup} component={Signup} />
          <Route exact path={ROUTES.payments.new} component={NewPayment} />
          <Route exact path={ROUTES.payments.success} component={PaymentSuccess} />

          <Route exact path={ROUTES.public.healthForms.landing} component={PublicHealthFormLanding} />
          <Route exact path={ROUTES.public.healthForms.new} component={PublicHealthFormNew} />
          <Route exact path={ROUTES.public.healthForms.created} component={PublicHealthFormCreated} />
          <Route exact path={ROUTES.public.healthForms.sign} component={PublicHealthFormSign} />
          <Route exact path={ROUTES.public.healthForms.signSuccess} component={PublicHealthFormSignSuccess} />
          <Route exact path={ROUTES.public.user} component={PublicUser} />

          <PrivateRoute exact path={ROUTES.dashboard} component={Dashboard} />
          <PrivateRoute exact path={ROUTES.registrations.new} component={NewRegistration} />
          <PrivateRoute exact path={ROUTES.registrations.edit} component={EditRegistration} />
          <PrivateRoute exact path={ROUTES.healthForms.edit} component={EditHealthForm} />
          <PrivateRoute exact path={ROUTES.campers.new} component={NewCamper} />
          <PrivateRoute exact path={ROUTES.campers.edit} component={EditCamper} />
          <PrivateRoute exact path={ROUTES.profile} component={Profile} />
          <PrivateRoute exact path={ROUTES.payments.show} component={ViewPayment} />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>,
  appElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

if (process.env.REACT_APP_LOGROCKET_ID) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID)
}
