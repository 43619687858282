import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../constants'
import { formatDate, formatCamperType, urlFor, isLeaders } from 'utils'

const DashboardAreaCamps = ({ id, areaCamps, user, area, ylCamp, full }) => (
  <div className="flex-box">
    <div className="content">
      <h2 className="flex-title flex-title--padding">{`${user && area(user).attributes['name']} Camps`} </h2>
      {areaCamps.length > 0 ? (
        <p>Check out the camp options for this year!</p>
      ) : (
        <p>No camps are available yet, check back soon!</p>
      )}
      {areaCamps &&
        areaCamps.map(areaCamp => (
          <DashboardAreaCamp
            key={areaCamp.id}
            id={areaCamp.id}
            name={areaCamp.attributes.name}
            startDate={ylCamp(areaCamp).attributes['start-date']}
            endDate={ylCamp(areaCamp).attributes['end-date']}
            camperType={ylCamp(areaCamp).attributes['camper-type']}
            description={areaCamp.attributes.description}
            registrationsOpen={ylCamp(areaCamp).attributes['start-date'] < new Date()}
            spaceRemaining={areaCamp.attributes['attendance-limit'] - areaCamp.attributes['total-registered']}
            ylSpaceRemaining={
              ylCamp(areaCamp).attributes['attendance-limit'] - ylCamp(areaCamp).attributes['total-registered']
            }
            full={areaCamp.attributes['attendance-limit'] <= areaCamp.attributes['total-registered']}
            ylFull={ylCamp(areaCamp).attributes['attendance-limit'] <= ylCamp(areaCamp).attributes['total-registered']}
          />
        ))}
      <p>
        <Link to={urlFor(ROUTES.public.user, { token: user.attributes['public-token'] })} className="btn info">
          {isLeaders
            ? 'Share your support page with a friend'
            : 'Give others the opportunity to help your child go to camp'}
        </Link>
      </p>
    </div>
  </div>
)

const DashboardAreaCamp = ({
  id,
  name,
  startDate,
  endDate,
  camperType,
  description,
  registrationsOpen,
  spaceRemaining,
  ylSpaceRemaining,
  full,
  ylFull,
}) => {
  const status = registrationsOpen
    ? { space: ylSpaceRemaining >= 0 ? ylSpaceRemaining : 0, full: ylFull }
    : { space: spaceRemaining >= 0 ? spaceRemaining : 0, full: full }
  return (
    <div className="card">
      <div className="card-header">
        <div>
          <b>{name} </b>
          <br />
          {formatDate(startDate, 'yyyy-MM-dd')} - {formatDate(endDate, 'yyyy-MM-dd')}
        </div>
      </div>
      <div className="card-copy">
        <dl>
          <dt>Type</dt>
          <dd>{formatCamperType(camperType)}</dd>
          <dt>Description</dt>
          <dd dangerouslySetInnerHTML={{ __html: description}}/>
        </dl>
      </div>
      <div className="card-footer">
        {`${status.space} spot${status.space > 1 ? 's' : ''} remaining `}

        <Link to={urlFor(ROUTES.registrations.new, { areaCampId: id })} className="btn">
          {status.full ? 'Register for Waiting List!' : 'Register Now!'}
        </Link>
      </div>
    </div>
  )
}

export default DashboardAreaCamps
