import React from 'react'
import SlickSlider from 'react-slick'

const slickSettings = {
  slidesToShow: 1,
  arrows: false,
  dots: false,
  autoplay: true,
  autoplaySpeed: 5000,
  fade: true,
}

export default ({ items }) => (
  <div className="carousel">
    <SlickSlider {...slickSettings}>
      {items.map((item, index) => (
        <div key={index} className="carousel__slide">
          {item}
        </div>
      ))}
    </SlickSlider>
  </div>
)
