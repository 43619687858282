const DEFAULT_STATE = {
  messages: [],
}

export default (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case 'FLASH_ADD':
      return {
        ...state,
        messages: state.messages.concat([payload]),
      }
    case 'FLASH_REMOVE':
      return {
        ...state,
        messages: state.messages.filter(msg => !(msg.message === payload.message && msg.kind === payload.kind)),
      }
    default:
      return state
  }
}
