import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { object, string } from 'yup'
import { query } from 'redux-bees'
import { find } from 'lodash'
import { subDays } from 'date-fns'

import { login } from 'actions'
import { isLoggedIn } from 'selectors'
import { Layout, Input, InlineError } from 'components'
import { ROUTES } from '../../constants'
import api from 'api'
import { jsonapiToFormErrors, titleize, urlFor, isLeaders } from 'utils'

const validationSchema = object().shape({
  camper_id: string().required(),
  area_camp_id: string().required(),
})

const CamperDetailFields = ({ areaCamp, status }) => (
  <div>
    {areaCamp.attributes.description && <p dangerouslySetInnerHTML={{ __html: areaCamp.attributes.description }} />}
    {areaCamp.attributes['required-camper-details'].length > 0 && (
      <Fragment>
        <h4>Additional Details</h4>
        {status && status.apiErrors['camper_details'] && (
          <InlineError>{status.apiErrors['camper_details']}</InlineError>
        )}
        {areaCamp.attributes['required-camper-details'].map((fieldName, i) => (
          <Field
            key={i}
            component={Input}
            name={fieldName}
            label={titleize(fieldName.replace(/_/g, ' '))}
            type="text"
          />
        ))}
      </Fragment>
    )}
  </div>
)

const RegistrationForm = compose(
  query('campers', api.getCampers, (perform, _props) => perform()),
  query('areaCamps', api.getAreaCamps, (perform, _props) => perform({ active: true }))
)(({ campers, areaCamps, initialAreaCampId, onSubmit }) => (
  <Formik
    initialValues={{
      camper_id: '',
      area_camp_id: initialAreaCampId,
    }}
    validationSchema={validationSchema}
    onSubmit={(values, formOptions) => {
      const selectedAreaCamp = find(areaCamps, areaCamp => areaCamp.id === values.area_camp_id)
      const selectedCamper = find(campers, camper => camper.id === values.camper_id)
      onSubmit(values, formOptions, selectedAreaCamp, selectedCamper)
    }}
    render={({ errors, values, status }) => {
      const selectedAreaCamp = find(areaCamps, areaCamp => areaCamp.id === values.area_camp_id)
      return (
        <Form>
          <Link to={`${ROUTES.campers.new}/?camp=${values.area_camp_id}`}>Add a new camper</Link> or
          <div className="form__group">
            <Field
              component={Input}
              type="select"
              name="camper_id"
              placeholder={isLeaders ? 'Select a leader' : 'Select a camper'}
              label="Camper"
              options={
                campers
                  ? campers.map(camper => ({
                      label: [camper.attributes['first-name'], camper.attributes['last-name']].join(' '),
                      value: camper.id,
                    }))
                  : []
              }
            />
            <Field
              component={Input}
              type="select"
              name="area_camp_id"
              placeholder="Camp"
              label="Camp"
              options={
                areaCamps
                  ? areaCamps.map(areaCamp => ({
                      label: areaCamp.attributes['name'],
                      value: areaCamp.id,
                    }))
                  : []
              }
            />
            {selectedAreaCamp && <CamperDetailFields areaCamp={selectedAreaCamp} values={values} status={status} />}
          </div>
          {status && status.apiErrors['base'] && <div className="errors--base">{status.apiErrors['base']}</div>}
          <button type="submit">Sign Up</button>
          <Link className="" to={urlFor(ROUTES.dashboard, { tab: 'campers' })}>
            Cancel
          </Link>
        </Form>
      )
    }}
  />
))

const mapState = state => ({
  isLoggedIn: isLoggedIn(state),
})

const mapDispatch = {
  login: login,
}

export default connect(
  mapState,
  mapDispatch
)(
  class NewRegistration extends Component {
    onSubmit(values, { setSubmitting, setStatus }, areaCamp, camper) {
      const { camper_id, area_camp_id, ...camper_details } = values
      api
        .createRegistration({
          registration: { camper_id: camper_id, area_camp_id: area_camp_id, camper_details: camper_details },
        })
        .then(response => {
          const registration = response.body.data
          const registrationToken = registration.attributes['token']
          const depositRequired = parseFloat(areaCamp.attributes['deposit'])
          const healthForm = registration.relationships['health-form'].data
          const camperInfoNeedsConfirmation = Date.parse(camper.attributes['updated-at']) < subDays(new Date(), 30)

          const next = () => {
            if (camperInfoNeedsConfirmation)
              return [urlFor(ROUTES.campers.edit, { id: camper.id }, { confirm: true }), healthForm && `health_form=${healthForm.id}`].filter(Boolean).join('&')

            if (healthForm && healthForm.id)
              return urlFor(ROUTES.healthForms.edit, { id: healthForm.id })

            if (depositRequired > 0)
              return `${urlFor(ROUTES.payments.new, {
                id: registrationToken,
              })}?deposit=${depositRequired}`

            return urlFor(ROUTES.dashboard, { tab: 'campers' })
          }
          this.props.history.push(next())
        })
        .catch(err => {
          jsonapiToFormErrors(values, err, setStatus)
          setSubmitting(false)
        })
    }
    render() {
      const { match } = this.props

      return (
        <Layout>
          <div className="form-container">
            <div className="form-container__inner">
              <h1>Register</h1>
              <RegistrationForm
                initialAreaCampId={match.params.areaCampId}
                onSubmit={(...args) => this.onSubmit(...args)}
              />
            </div>
          </div>
        </Layout>
      )
    }
  }
)
