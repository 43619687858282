import React from 'react'

import { Layout } from 'components'
import { isLeaders } from 'utils'

export default () => (
  <Layout>
    <div className="container">
      <h1>Camp Registration Help</h1>
      <h3>Dashboard</h3>
      <ul>
        <li>Once you have logged in, you will be brought to the dashboard</li>
        <li>
          The dashboard contains the current area camps, payments that have been made and the{' '}
          {isLeaders ? 'leader' : 'camper'}s you have added and registered
        </li>
        <li>From the dashboard you can add {isLeaders ? 'leader' : 'camper'}s and register them for camp</li>
      </ul>
      <br />
      <h3>Registration</h3>
      <ul>
        <li>
          To register someone for camp, you can either add a {isLeaders ? 'leader' : 'camper'} first or just click
          Register Now for the area camp that you wish to register for.
        </li>
        <li>
          If there are no previous {isLeaders ? 'leader' : 'camper'}s already added, you will be taken to the Add a{' '}
          {isLeaders ? 'Leader' : 'Camper'} page.
        </li>
        <li>
          There you will will fill out name, address, birthdate, shirt size, school, grade, parents name and allergy
          information of the camp registrant.
        </li>
        <li>
          Once you have completed adding a {isLeaders ? 'leader' : 'camper'}, you will then be taken to the Registration
          section, where you can choose the {isLeaders ? 'leader' : 'camper'} and the camp that you would like to
          register for.
        </li>
        <li>
          Once you have registered a {isLeaders ? 'leader' : 'camper'}, you will receive a confirmation email of the
          details.
        </li>
        <li>
          If for unexpected reasons you need to withdraw a registration, you can do so by clicking the Request Removal
          button on registrations dashboard.
        </li>
      </ul>
      <br />
      <h3>Payment</h3>
      <ul>
        <li>
          Once you have registered a {isLeaders ? 'leader' : 'camper'}, you are brought to the credit card payment page
          where you can pay the deposit or any greater amount.
        </li>
        <li>
          If you plan on paying by cheque or other means, please contact the local area staff to arrange for that
          payment.
        </li>
        <li>
          Once any non credit card payment has been received by the local area staff, you will see that payment appear
          on the payment dashboard credited to the appropriate {isLeaders ? 'leader' : 'camper'}.
        </li>
        <li>
          Once a {isLeaders ? 'leader' : 'camper'} is registered and initial deposits have been paid, you can continue
          to make additional credit card payments.
        </li>
        <li>
          If you have family or friends that you would like to give the opportunity to help contribute to sending your
          child to camp, you can press{' '}
          {isLeaders
            ? 'Share your support page with a friend'
            : 'Give others the opportunity to help your child go to camp'}{' '}
          button, that will bring you to a public accessible page that allows them to make an online credit card payment
          without requiring them to log into your account.
        </li>
        <li>Please note that any payments to a {isLeaders ? 'leader' : 'camper'} are not tax receiptable.</li>
      </ul>
      <br />
      <p>
        If you have further questions or are in need of technical assistance please contact your local staff person or
        email <a href="mailto:itservices@younglife.ca">itservices@younglife.ca</a>
      </p>
    </div>
  </Layout>
)
