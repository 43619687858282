export const ROUTES = {
  login: '/login',
  dashboard: '/dashboard/:tab?',
  profile: '/profile',
  help: '/help',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:token',
  signup: '/create-account/',
  registrations: {
    new: '/registrations/new/:areaCampId',
    edit: '/registrations/edit/:id',
  },
  healthForms: {
    edit: '/health-forms/:id',
  },
  campers: {
    new: '/campers/new',
    edit: '/campers/:id',
  },
  public: {
    pay: '/public/registrations/:registrationToken/pay',
    user: '/public/user/:token',
    healthForms: {
      landing: '/public/health-forms',
      new: '/public/health-forms/:type/new',
      created: '/public/health-forms/created',
      sign: '/public/health-forms/:token/sign',
      signSuccess: '/health-forms/signed',
    },
  },
  payments: {
    show: '/payments/:id',
    new: '/payments/new/:id',
    success: '/payments/success/:id',
  },
}
