import React from 'react'
import { Field, FieldArray } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input } from 'components'

export default ({ value, name, itemLabel, addText = 'Add', readOnly, ...rest }) => (
  <FieldArray
    name={name}
    {...rest}
    render={arrayHelpers => (
      <div>
        {value &&
          value.length > 0 &&
          value.map((itemValue, index) => (
            <div className="list-field" key={index}>
              <div className="list-field__row">
                <Field component={Input} name={`${name}.${index}`} label={itemLabel} readOnly={readOnly} />
                {!readOnly && (
                  <button className="btn danger" type="button" onClick={() => arrayHelpers.remove(index)}>
                    <FontAwesomeIcon icon={['far', 'trash-alt']} />
                  </button>
                )}
              </div>
            </div>
          ))}
        {!readOnly && (
          <p>
            <button type="button" className="gray" onClick={() => arrayHelpers.push('')}>
              {addText}
            </button>
          </p>
        )}
      </div>
    )}
  />
)
