export const postLogin = async ({ email, password }) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/sessions`, {
    method: 'POST',
    body: JSON.stringify({
      user: {
        email: email,
        password: password,
      },
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return await response.json()
}

// export const postResetPassword = async ({ email }) => {
//   const response = await fetch('http://localhost:5000/api/v1/password_resets', {
//     method: 'POST',
//     body: JSON.stringify({
//       password_reset: {
//         email: email,
//       },
//     }),
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   })
//   return await response.json()
// }
