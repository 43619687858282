import { postLogin } from 'api'

export const login = ({ email, password }) => async dispatch => {
  const response = await postLogin({ email: email, password: password })
  const authInfo = response.data
  dispatch({
    type: 'USER_LOGIN',
    payload: {
      token: authInfo.attributes.token,
      email: authInfo.attributes.email,
      firstName: authInfo.attributes['first-name'],
      userId: authInfo.id,
    },
  })
}

export const loginAfterSignup = ({ token, email, firstName, userId }) => dispatch => {
  dispatch({
    type: 'USER_LOGIN',
    payload: {
      token: token,
      email: email,
      firstName: firstName,
      userId: userId,
    },
  })
}

export const logout = () => dispatch => dispatch({ type: 'USER_LOGOUT' })

export const flashMessage = (message, kind = 'success') => dispatch => {
  dispatch({ type: 'FLASH_ADD', payload: { message, kind } })
  setTimeout(() => dispatch({ type: 'FLASH_REMOVE', payload: { message, kind } }), 3000)
}
