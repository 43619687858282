import React from 'react'
import { includes } from 'lodash'

class CheckboxList extends React.Component {
  getChecked(option) {
    const { values, name } = this.props
    return includes(values[name], option)
  }

  select(option, checked) {
    const { setFieldValue, values, name } = this.props

    let newValue = values[name] || []
    if (checked === false) {
      newValue = newValue.filter(val => val !== option)
    } else {
      newValue.push(option)
    }
    setFieldValue(name, newValue)
  }

  render() {
    const { name, options, columns, readOnly } = this.props
    return (
      <table className="option-list">
        <thead>
          <tr>
            <th />
            {columns.map((name, index) => (
              <th key={index}>{name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {options.map((option, index) => (
            <tr key={index}>
              <td>{option}</td>
              {columns.map((col, index) => (
                <td key={index}>
                  <input
                    type="checkbox"
                    disabled={readOnly}
                    name={`${name}.${index}`}
                    value={option}
                    checked={this.getChecked(option)}
                    onChange={e => this.select(option, e.target.checked)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }
}

export default CheckboxList
