import React from 'react'
import { default as ReactModal } from 'react-modal'

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()
  }

  setFocus() {
    const focusableChildren = this.container.current.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    )
    if (focusableChildren.length > 0) {
      focusableChildren[0].focus()
    }
  }

  render() {
    const { onAfterOpen, children, ...restProps } = this.props

    return (
      <ReactModal
        className={'modal'}
        onAfterOpen={() => {
          this.setFocus()
          onAfterOpen && onAfterOpen()
        }}
        {...restProps}
      >
        <div ref={this.container}>
          {children}

          <button type="button" className="link" onClick={this.props.onRequestClose}>
            Cancel
          </button>
        </div>
      </ReactModal>
    )
  }
}

export default Modal
