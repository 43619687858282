import React, { Component } from 'react'
import { compose } from 'redux'
import { query } from 'redux-bees'
import { Link } from 'react-router-dom'

import api from 'api'
import { Carousel, Layout } from 'components'
import { ROUTES } from '../../constants'
import { urlFor, isLeaders } from 'utils'

const areaQuery = isLeaders
  ? query('areas', api.getAreas, (perform, _props) => perform({ leaders_only: true }))
  : query('areas', api.getAreas)

export default compose(areaQuery)(
  class Landing extends Component {
    state = { selectedArea: null }
    render() {
      return (
        <Layout
          headerBackground={
            <Carousel
              items={[
                <img className="carousel-image" src="assets/RR.jpg" alt="Summer" />,
                <img className="carousel-image" src="assets/Saranac.jpg" alt="Summer" />,
                <img className="carousel-image" src="assets/Caribe.jpg" alt="Summer" />,
                // <img className="carousel-image" src="assets/winter-group-girls.jpg" alt="Winter" />,
                // <img className="carousel-image" src="assets/christmas-RR-canyon-winter.jpg" alt="Winter" />,
                // <img className="carousel-image" src="assets/snow-playground-winter.jpg" alt="Winter" />,
                // <img className="carousel-image" src="assets/winter-tubing.jpg" alt="Winter" />,
                // <img className="carousel-image" src="assets/RR-hockey-winter.jpg" alt="Winter" />,
              ]}
            />
          }
          headerTitle={<h1>The best week of your life!</h1>}
          headerSize="md"
        >
          <div className="container">
            {isLeaders ? (
              <>
                <h3>Welcome to Young Life of Canada’s leadership camp registration system!</h3>
                <p>
                  Young Life leader retreats and camps are a highlight for leaders where we get together as a group of
                  leaders to focus on growth in faith, leadership and have fun.
                </p>
                <p>
                  If this is your first time registering yourself for a Young Life leader retreat or camp, please create
                  a new leader account.
                </p>
                <br />
                <p>
                  <b>To create a leader account:</b>{' '}
                  <Link to={urlFor(ROUTES.signup)} className="btn success sm btn--height">
                    Create an Account
                  </Link>
                </p>

                <p>
                  <b>Already have an account? </b>{' '}
                  <Link className="btn btn--sm" to="/login">
                    Log in here
                  </Link>
                </p>
              </>
            ) : (
              <>
                <h3>Welcome to Young Life of Canada’s camp registration system!</h3>
                <p>
                  Young Life camps are a highlight for teens and focus on what matters most to them – fun, adventure,
                  friendship and a sense of significance. Teens attend with their YL leader whom they’ve come to know
                  throughout the school year, and experience what many describe as ‘the best week of their lives.’
                </p>
                <p>
                  To learn more about Young Life, visit <a href="https://www.younglife.ca/">YoungLife.ca</a>.
                </p>
                <p>
                  If this is your first time registering your teen for a Young Life camp, please create a family
                  account.
                </p>
                <br />
                <p>
                  <b>To create a family account:</b>{' '}
                  <Link to={urlFor(ROUTES.signup)} className="btn success sm btn--height">
                    Create an Account
                  </Link>
                </p>

                <p>
                  <b>Already have an account? </b>{' '}
                  <Link className="btn btn--sm" to="/login">
                    Log in here
                  </Link>
                </p>
              </>
            )}
            {/* <p>
              <b>
                If you just need to fill out a health form, please{' '}
                <Link to={ROUTES.public.healthForms.landing}>click here</Link>
              </b>
            </p> */}
            <p>
              <br />
              If you have any questions about the registration process, please do not hesitate to{' '}
              <a href="https://www.younglife.ca/find-yl/">contact your local Young Life staff person</a>.
            </p>
          </div>
        </Layout>
      )
    }
  }
)
