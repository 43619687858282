import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getRelationship } from 'redux-bees'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../constants'
import { Loading } from 'components'
import { urlFor } from 'utils'

export default compose(
  connect((state, _props) => ({
    getCamper: registration => getRelationship(state, registration, 'camper'),
    getAreaCamp: registration => getRelationship(state, registration, 'area-camp'),
  }))
)(({ incompleteHealthForms, getAreaCamp, getCamper }) => {
  if (!incompleteHealthForms) return <div />

  return (
    <div>
      <div className="flex-border">
        <h2 className="flex-title">Incomplete Health Forms</h2>
        <p>
          You have some health forms that have not been completed. Please make sure you complete and sign before the
          first day of camp.
        </p>
        <table className="table-minimal">
          <tbody>
            {incompleteHealthForms.map((form, i) => {
              const camper = getCamper(form.registration)
              const areaCamp = getAreaCamp(form.registration)
              if (!camper || !areaCamp) return <Loading />
              return (
                <tr key={i}>
                  <td>
                    <b>
                      {camper.attributes['first-name']} {camper.attributes['last-name']}
                    </b>{' '}
                    - {areaCamp.attributes.name}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <p>
                      <i>{form.attributes.status}</i>
                    </p>
                    <p>
                      <Link to={urlFor(ROUTES.healthForms.edit, { id: form.id })} className="btn small">
                        edit
                      </Link>
                    </p>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
})
