import React, { Fragment } from 'react'
import cn from 'classnames'
import { default as ReactSelect } from 'react-select'
import { find, get } from 'lodash'
import DatePicker from 'react-datepicker'
import InputMask from 'react-input-mask'
import { parseISO, format } from 'date-fns'
import { Signature, Initial } from 'components'

export const InlineError = ({ children }) => <div className="inline-error">{children}</div>

// Wrapper to tie react-select in with Formik, similar to: https://codesandbox.io/s/73jj9zom96
export const Select = ({ name, ...props }) => (
  <ReactSelect
    {...props}
    name={name}
    onChange={selectedOption => props.onChange(name, selectedOption.value)}
    onBlur={() => props.onBlur(name, true)}
  />
)

export class Input extends React.Component {
  clearApiError() {
    const {
      field,
      form: { setStatus, status },
    } = this.props
    if (status && status.apiErrors[field.name]) {
      let newStatus = status
      delete newStatus.apiErrors[field.name]
      setStatus(newStatus)
    }
  }

  onChange(...args) {
    const { field, ...props } = this.props
    this.clearApiError()
    if (field.onChange) {
      field.onChange(...args)
    }
    if (props.onChange) {
      props.onChange(...args)
    }
  }

  render() {
    const {
      field, // { name, value, onChange, onBlur }
      form: { touched, errors, setFieldValue, setFieldTouched, status, setStatus }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      className,
      style,
      type,
      ...props
    } = this.props
    let hasError =
      (status && get(status.apiErrors, field.name)) || (get(touched, field.name) && get(errors, field.name))
    let errorMessage =
      status && get(status.apiErrors, field.name) ? get(status.apiErrors, field.name) : get(errors, field.name)
    if (type === 'signature') {
      const shortName = field.name.replace('_attributes', '')
      hasError = hasError || (status && get(status.apiErrors, `${shortName}_text`))
      errorMessage = hasError ? `Your signature is required.` : errorMessage
    } else if (type === 'initial') {
      const shortName = field.name.replace('_attributes', '')
      hasError = hasError || (status && get(status.apiErrors, shortName))
      errorMessage = hasError && get(status.apiErrors, shortName)
    }

    return (
      <div
        className={cn('input', { 'has-error': hasError }, { 'checkbox-row': type === 'checkbox' }, className)}
        style={style}
      >
        {props.label && <label htmlFor={field.name}>{props.label}</label>}
        {type === 'select' ? (
          <Select
            {...field}
            {...props}
            value={find(props.options, option => option.value === field.value)}
            onChange={(...args) => {
              setFieldValue(...args)
              this.clearApiError()
              // props.onChange && props.onChange(...args)
            }}
            onBlur={(...args) => {
              setFieldTouched(...args)
              // props.onBlur && props.onBlur(...args)
            }}
            isDisabled={props.readOnly}
          />
        ) : type === 'date' ? (
          <DatePicker
            disabled={props.readOnly}
            showYearDropdown
            dateFormat="yyyy-MM-dd"
            scrollableYearDropdown
            onChange={date => {
              setFieldValue(field.name, format(date, 'yyyy-MM-dd'))
              this.clearApiError()
              props.onChange && props.onChange(date)
            }}
            selected={(field.value && parseISO(field.value)) || null}
            maxDate={props.maxDate}
            minDate={props.minDate}
            openToDate={field.value ? null : props.openToDate}
            name={props.fieldName}
          />
        ) : type === 'textarea' ? (
          <textarea
            disabled={props.readOnly}
            className="form-control"
            {...field}
            {...props}
            value={field.value || ''}
            onChange={(...args) => this.onChange(...args)}
          />
        ) : type === 'hidden' ? (
          <input
            type="hidden"
            className="form-control"
            {...field}
            {...props}
            onChange={(...args) => this.onChange(...args)}
            value={field.value || ''}
          />
        ) : type === 'signature' ? (
          <Signature
            disabled={props.readOnly}
            isOpen={props.isOpen}
            onSign={props.onSign}
            onCancel={props.onCancel}
            field={field}
            form={{ setFieldValue, status, setStatus }}
            {...props}
          />
        ) : type === 'initial' ? (
          <Initial disabled={props.readOnly} field={field} form={{ setFieldValue, status, setStatus }} {...props} />
        ) : type === 'yes-no' ? (
          <Fragment>
            <label>
              <input
                id={`${field.name}1`}
                type="radio"
                {...field}
                {...props}
                value="yes"
                disabled={props.readOnly}
                checked={props.value === 'yes'}
                onChange={(...args) => this.onChange(...args)}
              />
              Yes
            </label>
            <label>
              <input
                id={`${field.name}2`}
                type="radio"
                {...field}
                {...props}
                value="no"
                checked={props.value === 'no'}
                disabled={props.readOnly}
                onChange={(...args) => this.onChange(...args)}
              />
              No
            </label>
          </Fragment>
        ) : type === 'checkbox' ? (
          <input
            type="checkbox"
            className="form-control"
            {...field}
            {...props}
            disabled={props.readOnly}
            onChange={(...args) => this.onChange(...args)}
          />
        ) : type === 'credit-card-number' ? (
          <InputMask
            disabled={props.readOnly}
            mask={props.ccType === 'AMX' ? '9999 9999 9999 999' : '9999 9999 9999 9999'}
            {...props}
            {...field}
            onChange={(...args) => this.onChange(...args)}
          />
        ) : type === 'credit-card-expiry' ? (
          <InputMask
            disabled={props.readOnly}
            mask="99/99"
            {...props}
            {...field}
            onChange={(...args) => this.onChange(...args)}
          />
        ) : type === 'credit-card-security-code' ? (
          <InputMask
            disabled={props.readOnly}
            mask={props.ccType === 'AMX' ? '9999' : '999'}
            {...props}
            {...field}
            onChange={(...args) => this.onChange(...args)}
          />
        ) : type === 'tel' ? (
          <InputMask
            className="form-control"
            disabled={props.readOnly}
            mask="(999) 999-9999"
            placeholder="xxx xxx xxxx"
            type={type}
            {...field}
            {...props}
            name={field.name}
            value={field.value || ''}
            onChange={(...args) => this.onChange(...args)}
          />
        ) : (
          <input
            className="form-control"
            disabled={props.readOnly}
            type={type || 'text'}
            {...field}
            {...props}
            name={field.name}
            value={field.value || ''}
            onChange={(...args) => this.onChange(...args)}
          />
        )}
        {hasError && <div className="errors">{errorMessage}</div>}
      </div>
    )
  }
}
