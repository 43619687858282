import { buildApi, get, post, put, destroy } from 'redux-bees'
import store from 'store'
import { getAuth } from 'selectors'

export * from './authentication'

const apiEndpoints = {
  getUser: { method: get, path: '/users/:id' },
  getPublicUser: { method: get, path: '/users/:token' },
  getAreas: { method: get, path: '/camp/areas' },
  getAreaCamps: { method: get, path: '/camp/area_camps' },
  getYlCamp: { method: get, path: '/camp/yl_camps/:id' },
  getPayments: { method: get, path: '/camp/payments' },
  getPayment: { method: get, path: '/camp/payments/:id' },
  createPayment: { method: post, path: '/camp/payments' },
  getCampers: { method: get, path: '/camp/campers' },
  getCamper: { method: get, path: '/camp/campers/:id' },
  createCamper: { method: post, path: '/camp/campers' },
  updateCamper: { method: put, path: '/camp/campers/:id' },
  getRegistrations: { method: get, path: '/camp/registrations' },
  getRegistration: { method: get, path: '/camp/registrations/:id' },
  getRegistrationByToken: { method: get, path: '/camp/registrations/:token' },
  createRegistration: { method: post, path: '/camp/registrations' },
  updateRegistration: { method: put, path: '/camp/registrations/:id' },
  deleteRegistration: { method: destroy, path: '/camp/registrations/:id' },
  getHealthForm: { method: get, path: '/camp/health_forms/:id' },
  getHealthFormForOtherSignatories: { method: get, path: '/camp/health_forms/:token/sign' },
  signHealthForm: { method: post, path: '/camp/health_forms/:token/sign' },
  updateHealthForm: { method: put, path: '/camp/health_forms/:id' },
  confirmHealthForm: { method: post, path: '/camp/health_forms/:id/confirm' },
  createHealthForm: { method: post, path: '/camp/health_forms' }, // Note - used only for public forms
  getArea: { method: get, path: '/camp/areas/:id' },
  requestPasswordReset: { method: post, path: '/password_resets' },
  getPasswordReset: { method: get, path: '/password_resets/:token' },
  updatePassword: { method: put, path: '/password_resets/:token' },
  signup: { method: post, path: '/users' },
  getHealthFormLandingMessage: { method: get, path: '/general_settings/health-form-landing-message' },
  getCampEventOptions: { method: get, path: '/general_settings/camp-or-yl-event' },
}

const config = {
  baseUrl: `${process.env.REACT_APP_API_URL}/api/v1`,
  configureHeaders(headers) {
    const { token, email } = getAuth(store.getState())
    return {
      ...headers,
      Authorization: `Token token="${token}", email="${email}"`,
    }
  },
  afterReject({ status, headers, body }) {
    if (status === 401) {
      // ie. redirect to login page
      store.dispatch({ type: 'USER_LOGOUT' })
    } else {
      return Promise.reject({ status, headers, body })
    }
  },
}

export default buildApi(apiEndpoints, config)
