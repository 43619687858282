import React, { Component } from 'react'
import { Layout, FormContainer } from 'components'

class SignSuccess extends Component {
  render() {
    return (
      <Layout>
        <FormContainer>
          <h2>You have successfully signed the health form.</h2>
          <h3>You may now close this window</h3>
        </FormContainer>
      </Layout>
    )
  }
}

export default SignSuccess
