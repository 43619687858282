import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import DashboardCamperRegistration from './registration'
import { ROUTES } from '../../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { urlFor, isLeaders } from 'utils'

const DashboardCampers = ({ campers, registrations, areaCamp, payments, healthForm }) => (
  <div className="flex-box flex-box-big">
    <h2 className="flex-title flex-title--padding">
      {isLeaders ? 'Leaders' : 'Campers'}{' '}
      <Link to={ROUTES.campers.new} className="btn btn--sm">
        Add
      </Link>
    </h2>
    <div className="content">
      <div className="flex-action"></div>
      {campers.length > 0 ? (
        campers.map(camper => (
          <DashboardCamper
            key={camper.id}
            camper={camper}
            registrations={registrations}
            areaCamp={areaCamp}
            healthForm={healthForm}
            payments={payments}
          />
        ))
      ) : (
        <p>
          You don't have any {isLeaders ? 'leader records' : 'campers'} associated with your account yet. Click 'Add' to
          create one!
        </p>
      )}
    </div>
  </div>
)

class DashboardCamper extends Component {
  render() {
    const { camper, registrations, areaCamp, payments, healthForm } = this.props
    let camperRegistrations = registrations(camper)
      .filter(Boolean)
      .filter(r => r.attributes['canceled-at'] == null) // Don't show cancelled registrations
    return (
      <div className="card">
        <div className="card-header">
          <div className="left">
            <b>{`${camper.attributes['first-name']} ${camper.attributes['last-name']}`}</b>
          </div>
          <div className="right">
            <Link to={urlFor(ROUTES.campers.edit, { id: camper.id })} className="btn gray sm">
              <FontAwesomeIcon icon={['far', 'edit']} />
            </Link>
            <a
              className="btn gray-light sm"
              href={`mailto:camp@younglife.ca?subject=Request a Cancellation; Camper: ${camper.id}`}
            >
              Request a Cancellation
            </a>
            <a
              className="btn gray-light sm"
              href={`mailto:camp@younglife.ca?subject=Archive Camper: ${camper.id}`}
            >
              Archive Camper
            </a>
          </div>
        </div>
        <div className="card-copy">
          <table className="table-minimal table-minimal--wrap-mobile">
            <thead>
              <tr>
                <th>Registration</th>
                <th>Health Form Status</th>
                <th>Payment</th>
                <th style={{ width: '320px' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {camperRegistrations && camperRegistrations.length > 0 ? (
                camperRegistrations.map(registration => (
                  <tr key={registration.id}>
                    <DashboardCamperRegistration
                      areaCamp={areaCamp}
                      registration={registration}
                      payments={payments}
                      healthForm={healthForm}
                    />
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>No registrations yet. Go to the register tab to register.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default DashboardCampers
