import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { query } from 'redux-bees'
import { Layout, Loading } from 'components'
import { formatDate } from 'utils'
import api from 'api'
import { getAuth } from 'selectors'

const mapState = state => ({
  authInfo: getAuth(state),
})

export default compose(
  connect(mapState),
  query('user', api.getUser, (perform, props) => perform({ id: props.authInfo.userId }))
)(
  class Profile extends Component {
    render() {
      const { user, status } = this.props

      return (
        <Layout>
          <div className="container">
            {status.user.hasStarted && status.user.isLoading && <Loading />}
            {status.user.hasFailed && (
              <p>
                Something went wrong, please reload the page and try again. Error: {JSON.stringify(status.user.error)}
              </p>
            )}
            {user && (
              <dl>
                <dt>Name</dt>
                <dd>
                  {user.attributes['first-name']} {user.attributes['last-name']}
                </dd>
                <dt>Email</dt>
                <dd>{user.attributes['email']}</dd>
                <dt>Account Created</dt>
                <dd>{formatDate(user.attributes['created-at'], 'yyyy-MM-dd')}</dd>
                <dt>Profile Last Updated</dt>
                <dd>{formatDate(user.attributes['updated-at'], 'yyyy-MM-dd')}</dd>
              </dl>
            )}
          </div>
        </Layout>
      )
    }
  }
)
