import React, { Component } from 'react'
import { connect } from 'react-redux'

import { login, flashMessage } from 'actions'
import { isLoggedIn } from 'selectors'
import { Layout, FormContainer } from 'components'
import { ROUTES } from '../../constants'
import api from 'api'
import { jsonapiToFormErrors, urlFor, isLeaders } from 'utils'
import CamperForm from './form'

const mapState = state => ({
  isLoggedIn: isLoggedIn(state),
})

const mapDispatch = {
  login: login,
  flash: flashMessage,
}

export default connect(
  mapState,
  mapDispatch
)(
  class NewCamper extends Component {
    onSubmit(values, { setSubmitting, setStatus }) {
      api
        .createCamper({
          camper: values,
        })
        .then(response => {
          const next = () => {
            const campId = this.getNextPage()
            if (!!campId) {
              return urlFor(ROUTES.registrations.new, { areaCampId: campId })
            } else {
              return urlFor(ROUTES.dashboard, { tab: 'campers' })
            }
          }
          this.props.flash('Registration Successful.')
          this.props.history.push(next())
        })
        .catch(err => {
          jsonapiToFormErrors(values, err, setStatus)
          setSubmitting(false)
        })
    }
    getNextPage() {
      if (this.props.location.search) {
        const params = new URLSearchParams(this.props.location.search)
        return params.get('camp')
      }
      return false
    }
    render() {
      return (
        <Layout>
          <FormContainer>
            <h2>Add a {isLeaders ? 'Leader' : 'Camper'}</h2>
            <CamperForm camper={false} onSubmit={(...args) => this.onSubmit(...args)} />
          </FormContainer>
        </Layout>
      )
    }
  }
)
