import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { query } from 'redux-bees'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import cn from 'classnames'
import { Formik, Form, Field, FastField } from 'formik'
import { differenceInYears, subYears, addMonths, addWeeks } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { object, string, array } from 'yup'

import api from 'api'
import { Input, MultipleChoiceGrid, Notice, ListField } from 'components'
import { ROUTES } from '../../constants'
import {
  dashToUnderscoreObject,
  getGendersForSelect,
  getProvincesForSelect,
  getMedicalConditionsForForm,
  getImmunizationsForForm,
  getDietOptionsForForm,
  titleize,
  formatDate,
  isLeaders,
  urlFor,
} from 'utils'

const INITIAL_TYPES = [
  'permission_to_attend',
  'medical_treatment',
  'covid_19_and_other_infectious_diseases',
  'health_coverage',
  'assumption_of_risks',
  'release_and_waiver_of_liability',
  'indemnity',
  'jurisdiction',
  'behaviour_and_dismissal',
  'lost_items',
  'promotional_photos',
]

const validationSchema = object().shape({
  email_address: string()
    .email('invalid email format')
    .nullable(),
  travel_email: string()
    .email()
    .nullable(),
  witness_email: string()
    .email()
    .nullable(),
  other_signatories: array()
    .of(string().email())
    .nullable(),
  home_phone: string().nullable(),
  cell_phone: string().nullable(),
  alternate_emergency_contact_phone: string().nullable(),
  emergency_contact_phone: string().nullable(),
  family_physician_phone: string().nullable(),
})

const areaQuery = isLeaders
  ? query('areas', api.getAreas, (perform, _props) => perform({ leaders_only: true }))
  : query('areas', api.getAreas)
const AreaSelect = compose(areaQuery)(({ areas, readOnly, name, description }) => (
  <div>
    {areas ? (
      <FastField
        readOnly={readOnly}
        component={Input}
        type="select"
        name={name}
        description={description}
        label="Young Life Area:"
        options={areas.map(area => ({
          label: [area.attributes.name, area.attributes.description].filter(Boolean).join(' - '),
          value: area.attributes.name,
        }))}
      />
    ) : (
      <p>Loading areas...</p>
    )}
  </div>
))

const campEventOptionsQuery = query('campEventOptions', api.getCampEventOptions)
const CampSelect = compose(campEventOptionsQuery)(({ values, signOnly, readOnly, campEventOptions }) => {
  const optionString = get(campEventOptions, 'attributes.setting', '[]')
  const options = JSON.parse(optionString)
  if (!options || options.length === 0) return <p>Loading events...</p>
  const fieldValues = options.includes(values.camp_event)
    ? { select: values.camp_event, other: '' }
    : { select: 'Other', other: values.camp_event }
  return (
    <fieldset>
      <FastField
        readOnly={readOnly}
        component={Input}
        type="select"
        name="camp_event"
        value={fieldValues.select}
        label="Camp or YL Event:"
        options={options.concat(['Other']).map(option => ({
          label: option,
          value: option,
        }))}
      />
      {values.camp_event === 'Other' && (
        <FastField
          readOnly={signOnly}
          component={Input}
          type="text"
          name="other_camp_event"
          value={fieldValues.other}
          label="Name of Other Camp or YL Event:"
        />
      )}
      {values.camp_event && (values.camp_event === 'Other' || !values.camp_event.includes(' in ')) && (
        <FastField readOnly={readOnly} component={Input} type="text" name="city_camp_event" label="City/Location:" />
      )}
    </fieldset>
  )
})

class Page extends Component {
  hasError = () => {
    const { errors } = this.props
    return errors
      ? Object.keys(errors)
          .map(error => {
            return this.fieldNames.includes(error)
          })
          .includes(true)
      : false
  }

  page = React.createRef()

  fieldNames = []

  componentDidMount = () => {
    // all signatures are going on the last page, so if a node has the name 'signature'
    // then add the signature fields to this page's field names
    this.page.current.querySelectorAll('input, textarea, select').forEach(node => {
      if (node.name === 'signature') {
        this.fieldNames.push('primary_signature_text')
        this.fieldNames.push('travel_signature_text')
        this.fieldNames.push('witness_signature_text')
      } else {
        this.fieldNames.push(node.name)
      }
    })
    this.fieldNames = this.fieldNames.filter((v, i, a) => a.indexOf(v) === i)
  }

  render() {
    const { children, visible } = this.props
    return (
      <div ref={this.page} style={{ display: visible ? 'block' : 'none' }}>
        {children}
      </div>
    )
  }
}

const PageButton = ({ onClick, active, completed, disabled, label, error, pageNumber }) => {
  const getPageIcon = () => {
    if (error) {
      return <FontAwesomeIcon icon={['far', 'exclamation-circle']} />
    } else if (completed) {
      return <FontAwesomeIcon icon={['far', 'check-circle']} />
    }
  }
  const getTooltip = () => {
    if (error) {
      return 'This page has errors that must be resolved'
    } else if (completed) {
      return 'You have completed this page'
    }
  }
  return (
    <div className="page-button__wrapper">
      <button
        type="button"
        className={cn(
          'inline-button',
          'page-button',
          { complete: completed },
          { incomplete: !completed },
          { active: active },
          { error: error }
        )}
        disabled={disabled}
        onClick={onClick}
      >
        <span className="page-button__number">{pageNumber}. </span>
        {label}
      </button>
      <div className={cn('page-button__icon', { complete: completed }, { error: error })} title={getTooltip()}>
        {getPageIcon()}
      </div>
    </div>
  )
}

export default class HealthForm extends Component {
  constructor() {
    super()
    this.pagesRefs = []
  }

  state = {
    highestPage: 1,
    latestInitialText: '',
  }

  getInitialValues = (healthForm, primarySignature, travelSignature, travelWitnessSignature, signOnly, signEmail) => {
    let values = {
      area_name: '',
      depart_date: '',
      return_date: '',
      first_name: '',
      last_name: '',
      birth_date: '',
      age: '',
      gender: '',
      email_address: '',
      cell_phone: '',
      home_phone: '',
      mailing_address: '',
      city: '',
      province: '',
      postal_code: '',
      emergency_contact_relationship: '',
      emergency_contact_name: '',
      emergency_contact_phone: '',
      family_physician_name: '',
      family_physician_phone: '',
      health_insurance_choice: '',
      province_health_insurance_policy_num: '',
      province_health_insurance_policy_province: '',
      foreign_medical_co_name: '',
      foreign_medical_co_emergency_phone_number: '',
      foreign_medical_co_contact_number: '',
      foreign_medical_co_policy_number: '',
      medical_conditions_detail: '',
      carry_inhaler_epipen_emergency_meds: '',
      carry_inhaler_epipen_emergency_meds_details: '',
      dietary_restrictions_details: '',
      allergies_details: '',
      medications_or_treatments_to_be_continued_at_camp: '',
      activities_to_be_limited: '',
      additional_information_for_doctor_or_property: '',
      non_dietary_allergies: '',
      non_dietary_allergies_life_threatening: '',
      within_weight_limit: '',
      primary_signature_attributes: {},
      travel_signature_attributes: {},
      witness_signature_attributes: {},
      current_medical_conditions: [],
      page: '',
      past_medical_conditions: [],
      up_to_date_immunizations: [],
      not_sure_immunizations: [],
      never_vaccinated_immunizations: [],
      dietary_restrictions_preferences: [],
      dietary_restrictions_allergies: [],
      dietary_restrictions_life_threatening: [],
      dietary_restrictions_other: '',
      other_signatories: [],
      health_form_type: this.props.type,
      travel_parent_name: '',
      travel_email: '',
      travel_child_name: '',
      travel_country: '',
      travel_departure_date: '',
      travel_return_date: '',
      witness_name: '',
      witness_email: '',
      custody_arrangements_detail: '',
      custody_arrangements_has_court_order: '',
      custody_arrangements_authorized_people: [],
      custody_arrangements_unauthorized_people: [],
      initials_attributes: INITIAL_TYPES.reduce((initials, initialType) => {
        initials[initialType] = get(this.props.initials, initialType) || null
        return initials
      }, {}),
    }
    if (!this.props.campEventSelect) {
      delete values.camp_event
      delete values.other_camp_event
      delete values.city_camp_event
    }
    if (healthForm) {
      values = {
        ...values,
        ...dashToUnderscoreObject(healthForm.attributes),
        health_insurance_choice: (() => {
          if (healthForm.attributes['province-health-insurance-policy-num']) return 'yes'
          else if (
            healthForm.attributes['foreign-medical-co-name'] ||
            healthForm.attributes['foreign-medical-co-policy-number']
          )
            return 'no'
          else return ''
        })(),
      }
    }
    if (primarySignature) {
      values = {
        ...values,
        primary_signature_attributes: {
          id: primarySignature.id,
          text: primarySignature.text,
          ip_address: primarySignature.ip_address,
          email: primarySignature.email,
          signature_type: primarySignature.signature_type,
        },
      }
    }
    if (travelSignature) {
      values = {
        ...values,
        travel_signature_attributes: {
          id: travelSignature.id,
          text: travelSignature.text,
          ip_address: travelSignature.ip_address,
          email: travelSignature.email,
          signature_type: travelSignature.signature_type,
        },
      }
    }
    if (travelWitnessSignature) {
      values = {
        ...values,
        witness_signature_attributes: {
          id: travelWitnessSignature.id,
          text: travelWitnessSignature.text,
          ip_address: travelWitnessSignature.ip_address,
          email: travelWitnessSignature.email,
          signature_type: travelWitnessSignature.signature_type,
        },
      }
    }
    if (signOnly) {
      values = {
        ...values,
        other_signatory_attributes: { text: '', ip_address: '', email: signEmail },
      }
    }
    return values
  }

  renderIntro(healthFormType) {
    switch (healthFormType) {
      case 'weekend':
        return (
          <Fragment>
            <h2>Weekend Camps, Day Trips & Sporting Events</h2>
            <h3>Informed Consent and Health Information</h3>
            <p>
              Young Life of Canada is collecting this personal information in accordance with British Columbia's{' '}
              <i>Personal Information Protection Act</i> for the purpose of hosting the individual named on the form
              safely at a Young Life camp or event.
            </p>
            <p>
              Please be aware that if the camper/guest is <i>under the age of 19</i>, a parent or legal guardian must
              complete and sign this form.
            </p>
            <p>
              In signing this form, you are consenting to Young Life sharing the personal information contained on this
              form with third-party health care personnel (e.g., phsycians, hospitals, ambulance personnel, etc.) for
              the purpose of providing medical care to the individual named on the form, in the event they become ill or
              are injured while at camp or during Young Life sponsored travel to and from camp.
            </p>
          </Fragment>
        )
      case 'out-of-country':
        return (
          <Fragment>
            <h2>Young Life of Canada travel outside Canada</h2>
            <h3>Informed Consent and Health Information</h3>
            <p>
              Young Life of Canada is collecting this personal information in accordance with British Columbia's{' '}
              <i>Personal Information Protection Act</i> for the purpose of hosting the individual named on the form
              safely at a Young Life camp or event.
            </p>
            <p>
              Please be aware that if the camper/guest is <i>under the age of 19</i>, a parent or legal guardian must
              complete and sign this form.
            </p>
            <p>
              In signing this form, you are consenting to Young Life sharing the personal information contained on this
              form with third-party health care personnel (e.g., physicians, hospitals, ambulance personnel, etc.) for
              the purpose of providing medical care to the individual named on the form, in the event they become ill or
              are injured while at camp or during Young Life sponsored travel to and from camp.
            </p>
          </Fragment>
        )
      default:
        return (
          <Fragment>
            <h2>Informed Consent and Health Information</h2>
            <p>
              Young Life of Canada is collecting this personal information in accordance with British Columbia's{' '}
              <i>Personal Information Protection Act</i> for the purpose of hosting the individual named on the form
              safely at a Young Life camp or event.
            </p>
            <p>
              Please be aware that if the camper/guest is <i>under the age of 19</i>, a parent or legal guardian must
              complete and sign this form.
            </p>
            <p>
              In signing this form, you are consenting to Young Life sharing the personal information contained on this
              form with third-party health care personnel (e.g., phsycians, hospitals, ambulance personnel, etc.) for
              the purpose of providing medical care to the individual named on the form, in the event they become ill or
              are injured while at camp or during Young Life sponsored travel to and from camp.
            </p>
          </Fragment>
        )
    }
  }

  renderConsent(signOnly, values) {
    const { latestInitialText } = this.state
    const areaField = (
      <Fragment>
        <fieldset>
          <AreaSelect name="area_name" readOnly={signOnly} />
          <FastField
            readOnly={signOnly}
            component={Input}
            type="date"
            name="depart_date"
            label="Date departing for camp:"
            minDate={new Date()}
            openToDate={addMonths(new Date(), 1)}
          />
          <FastField
            readOnly={signOnly}
            component={Input}
            type="date"
            name="return_date"
            label="Date returning from camp:"
            minDate={new Date()}
            openToDate={addWeeks(addMonths(new Date(), 1), 1)}
          />
          {this.props.campEventSelect && <CampSelect values={values} readOnly={signOnly} />}
        </fieldset>
      </Fragment>
    )

    return (
      <Fragment>
        <legend>Consent</legend>
        {areaField}
        <h4>Read thoroughly before signing:</h4>
        <p>
          <b>MEDICAL TREATMENT:</b> I hereby give permission to a qualified practitioner appointed 
          by Young Life of Canada (YLC) to provide medical treatment within their scope of practice.  
          I also agree to be transported to a local hospital in a medical emergency in the event that I am not 
          able to give verbal consent.  I also agree to consult a health care professional should I be reasonably 
          requested to do so by YLC.
        </p>
        <Field
          component={Input}
          type="initial"
          readOnly={signOnly}
          email={values.email_address}
          name="initials_attributes.medical_treatment"
          defaultText={latestInitialText}
          onTextChange={text => this.setState({ latestInitialText: text })}
        />
        <p>
          <b>COVID-19 AND OTHER INFECTIOUS DISEASES:</b> I understand that COVID-19 and other infectious diseases remain a 
          potential risk to which I may be exposed while visiting this YLC property.  I understand and agree that YLC will 
          follow all current and applicable public health protocols and I agree to follow all such public health protocols 
          while I am at this YLC property.
        </p>
        <Field
          component={Input}
          type="initial"
          readOnly={signOnly}
          email={values.email_address}
          name="initials_attributes.covid_19_and_other_infectious_diseases"
          defaultText={latestInitialText}
          onTextChange={text => this.setState({ latestInitialText: text })}
        />
        <p>
          <b>HEALTH COVERAGE:</b> I agree to provide YLC with evidence of current medical coverage under BC Medical or 
          equivalent.  I understand I may be billed for medical expenses not covered by my Provincial Health Plan, my 
          extended medical coverage, or my personal insurance plan.
        </p>
        <Field
          component={Input}
          type="initial"
          readOnly={signOnly}
          email={values.email_address}
          name="initials_attributes.health_coverage"
          defaultText={latestInitialText}
          onTextChange={text => this.setState({ latestInitialText: text })}
        />
        <p>
          <b>ASSUMPTION OF RISKS:</b> I understand YLC has undertaken to ensure the recreational activities provided to 
          you (which may include such activities as wide games, land and water sports, swimming and diving, ziplining, 
          dancing etc) and the activities are as safe as reasonably possible.  I understand there are inherent risks based 
          on my involvement in such activities.  I understand that it cannot be guaranteed that I will not be injured as the 
          result of participating in the activities.  I also understand YLC cannot guarantee a food-allergy-free environment. 
          I have provided to YLC my medical contact information, and complete lists of all my known allergies, medical 
          dietary restrictions, and current and past medical conditions, with appropriate detail and a list of medical 
          treatments and restrictions which will apply while I am at a YLC property (my disclosed pre-existing conditions). 
          I also confirm that I have no knowledge of any other physical or mental impairment that would be affected by, or 
          affect my, participation in the activities at a YLC property and believe that I am physically, mentally and 
          emotionally able to do so. I choose to accept all risks of injury to myself (including personal injury, disability 
          or death) illness, damage, loss, claim liability or expense of any kind (claims, including any claims based on the 
          actions, omissions or negligence of any YLC director, officer, employee, agent or representative) which may result, 
          arise out of occur in connection with (a) my participation in the activities, (b) my exposure to allergens 
          (c) a pre-existing health condition of any kind, whether or not disclosed to YLC and (d) my physical, mental or 
          emotional abilities.
        </p>
        <Field
          component={Input}
          type="initial"
          readOnly={signOnly}
          email={values.email_address}
          name="initials_attributes.assumption_of_risks"
          defaultText={latestInitialText}
          onTextChange={text => this.setState({ latestInitialText: text })}
        />
        <p>
          <b>REALEASE AND WAIVER OF LIABILITY:</b> I, for myself and my family members, estate, heirs, personal 
          representatives and assigns, if any, release, covenant not to sue, and waive any and all liability against, and 
          will not hold or seek to hold YLC or its directors, officers, employees, agents and representatives liable for any 
          sickness, illness, injury or infection or claims (including any claims based on the actions, omissions or 
          negligence of any YLC director, officer, employee, agent or representative) that result from or arise out of or in 
          connection with (a) my participation in the activities, (b) my exposure to allergens (c) a pre-existing health 
          condition of any kind, whether or not disclosed to YLC and (d) my physical, mental or emotional abilities, whether 
          or not such claims arise before, during, or after my visit to this YLC property.
        </p>
        <Field
          component={Input}
          type="initial"
          readOnly={signOnly}
          email={values.email_address}
          name="initials_attributes.release_and_waiver_of_liability"
          defaultText={latestInitialText}
          onTextChange={text => this.setState({ latestInitialText: text })}
        />
        <p>
          <b>INDEMNITY:</b> I will save and hold harmless the Directors, Officers, and all volunteers, employees of YLC and 
          any or all of their affiliates from any and all actions, causes of action, claims and demands resulting from any 
          loss, illness, sickness, injury or damage to my person or my property which has arisen or may arise from visit to, 
          camping or living at, or my participation in any activities at this YLC property including any YLC programs, 
          travel, activities, or otherwise.
        </p>
        <Field
          component={Input}
          type="initial"
          readOnly={signOnly}
          email={values.email_address}
          name="initials_attributes.indemnity"
          defaultText={latestInitialText}
          onTextChange={text => this.setState({ latestInitialText: text })}
        />
        <p>
          <b>JURISDICTION:</b> I understand any and all actions that may arise from this agreement or my camping, visiting, 
          or living at this YLC property will be governed by the laws of British Columbia, Canada and I consent to the 
          exclusive jurisdiction of the courts in British Columbia, Canada.
        </p>
        <Field
          component={Input}
          type="initial"
          readOnly={signOnly}
          email={values.email_address}
          name="initials_attributes.jurisdiction"
          defaultText={latestInitialText}
          onTextChange={text => this.setState({ latestInitialText: text })}
        />
        <p>
          <b>BEHAVIOUR AND DISMISSAL:</b> I will follow all the requirements for visiting, camping, and participating in 
          activities at this YLC property and the reasonable directions of all volunteers and employees of YLC.  The Director 
          of this YLC property or designate reserves the right to dismiss a guest without refund who, in his/her opinion, is 
          a hazard to the safety of others or who appears to have rejected the reasonable behavioural rules of this YLC 
          property .  I hereby give permission to the Director or designate to search my belongings or personal effects for 
          prohibited items if I am reasonably suspected of having same.  I agree to reimburse YLC for damage or defacement of 
          property attributable to my activity.
        </p>
        <Field
          component={Input}
          type="initial"
          readOnly={signOnly}
          email={values.email_address}
          name="initials_attributes.behaviour_and_dismissal"
          defaultText={latestInitialText}
          onTextChange={text => this.setState({ latestInitialText: text })}
        />
        <p>
          <b>LOST ITEMS:</b> YLC is not responsible for personal items which are lost, stolen or damaged.  Shipping of my 
          found items, if any, will be at my expense.
        </p>
        <Field
          component={Input}
          type="initial"
          readOnly={signOnly}
          email={values.email_address}
          name="initials_attributes.lost_items"
          defaultText={latestInitialText}
          onTextChange={text => this.setState({ latestInitialText: text })}
        />
        <p>
          <b>PROMOTIONAL PHOTOS:</b> I give permission to YLC or its designate to take and use photos, videos or any other 
          recording of me for use in promotional materials or camp videos, in any format now known or later devised, and YLC 
          or its designate may use my name in connection with such uses.
        </p>
        <Field
          component={Input}
          type="initial"
          readOnly={signOnly}
          email={values.email_address}
          name="initials_attributes.promotional_photos"
          defaultText={latestInitialText}
          optional={true}
          onTextChange={text => this.setState({ latestInitialText: text })}
        />
        <br />
        <p>
          <b>INFORMED CONSENT:</b> By signing below, I acknowledge that I am 19 years old, or older and that I have read, 
          understood and voluntarily agree to all the terms of this Waiver and Consent to Medical Treatment, including that 
          I am choosing to accept all risks of my participation in any and all aspects of the activities at this YLC 
          property, waiving liability on the terms set out above against YLC, and that I am giving informed consent to 
          medical treatment should such need arise.
        </p>
        <p>
          If the Guest is not 19 years old or older, the parent(s) or legal guardian(s) set out below have submitted this 
          form on behalf of the Guest, consent to the Guest attending this YLC property and:
        </p>
        <p>
          1. Have legal custody of the Guest.  If a custodial order is in place, this will be fully communicated to YLC 
          including a photocopy of the section of any court order referring to visitation rights.
        </p>
        <p>
          2. Understands that references to "I" or "the Guest" are to be read and understood as references to myself as 
          the parent/legal guardian.
        </p>
        <p>
          3. Have read, understood and voluntarily agree to all the terms of this Waiver and Consent to Medical Treatment set 
          out above, including that the parent/guardian, on their own behalf and on behalf of the Guest:
        </p>
        <p>
          (a) accepts all risks of the Guest's participation in any and all aspects of the activities at a YLC property
        </p>
        <p>
          (b) on the terms set out above: (i) waives liability against YLC (ii) gives informed consent to medical treatment 
          should such need arise;
        </p>
        <p>
          and (c) saves and holds harmless the Directors, Officers, and all volunteers, employees, 
          agents and representatives of YLC and any or all of its affiliates from any and all actions, causes of action, 
          claims and demands resulting from any loss, illness, sickness, injury or damage to the Guest's person or property 
          which has arisen or may arise from visit to, camping or living at, or my participation in any activities at this 
          YLC property including any YLC programs, travel, activities, or otherwise.
        </p>
      </Fragment>
    )
  }

  updatePage = (page, submitForm) => {
    const { history, validateOnPageChange } = this.props
    history.push(`${history.location.pathname}?page=${page}`)
    this.setState({ highestPage: Math.max(this.state.highestPage, page) })
    validateOnPageChange && submitForm()
    window.scrollTo(0, 0)
  }

  submitForm = (values, { setSubmitting, setStatus, setFieldValue }) => {
    const { onSubmit } = this.props

    onSubmit(values, { setSubmitting, setStatus, setFieldValue, values })
  }

  handleConfirm = async (values, { submitForm, setSubmitting, setStatus }) => {
    this.props.onConfirm(values, { setSubmitting, setStatus })
  }

  confirmCancel = e => {
    const confirmed = window.confirm('Are you sure you want to cancel? You will lose any unsaved changes.')
    !confirmed && e.preventDefault()
  }

  hasErrors = () => {
    return this.pagesRefs.filter(page => page.hasErrors()).length
  }

  componentDidMount = () => {
    const {
      healthForm,
      history: {
        location: { search },
      },
    } = this.props
    let currentPage = parseInt(search[search.length - 1])
    if (!currentPage) {
      const startingPage = healthForm && healthForm.attributes.page ? healthForm.attributes.page : 1
      this.props.history.replace(`${this.props.history.location.pathname}?page=${startingPage}`)
      currentPage = startingPage
    }
    this.setState({ highestPage: currentPage })
  }

  render() {
    const {
      healthForm,
      onConfirm,
      submitButton,
      primarySignature,
      travelSignature,
      travelWitnessSignature,
      signatures = [],
      signOnly = false,
      history: {
        location: { search },
      },
    } = this.props
    const { highestPage } = this.state
    const currentPage = parseInt(search[search.length - 1])
    return (
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={this.getInitialValues(
          healthForm,
          primarySignature,
          travelSignature,
          travelWitnessSignature,
          signOnly
        )}
        onSubmit={this.submitForm}
        render={({ values, setSubmitting, isSubmitting, setStatus, setFieldValue, status, submitForm }) => {
          return (
            <Form className="form">
              {this.renderIntro(values.health_form_type)}
              <div className="progress-buttons__legend">
                <span className="progress-buttons__legend-item error">
                  <FontAwesomeIcon className="icon" icon={['far', 'exclamation-circle']} />: Page has errors
                </span>
                <span className="progress-buttons__legend-item complete">
                  <FontAwesomeIcon className="icon" icon={['far', 'check-circle']} />: Page complete
                </span>
              </div>
              <div id="formProgress" className="progress-buttons button-row" aria-label={`Step ${currentPage} of 5`}>
                <PageButton
                  active={currentPage === 1}
                  completed={highestPage > 1}
                  error={this.pagesRefs[1] && this.pagesRefs[1].hasError()}
                  disabled={isSubmitting}
                  label="Guest Information"
                  onClick={() => this.updatePage(1, submitForm)}
                  pageNumber={1}
                />
                <PageButton
                  active={currentPage === 2}
                  completed={highestPage > 2}
                  error={this.pagesRefs[2] && this.pagesRefs[2].hasError()}
                  disabled={isSubmitting}
                  label="Contact Information"
                  onClick={() => this.updatePage(2, submitForm)}
                  pageNumber={2}
                />
                <PageButton
                  active={currentPage === 3}
                  completed={highestPage > 3}
                  error={this.pagesRefs[3] && this.pagesRefs[3].hasError()}
                  disabled={isSubmitting}
                  label="Dietary Restrictions"
                  onClick={() => this.updatePage(3, submitForm)}
                  pageNumber={3}
                />
                <PageButton
                  active={currentPage === 4}
                  completed={highestPage > 4}
                  error={this.pagesRefs[4] && this.pagesRefs[4].hasError()}
                  disabled={isSubmitting}
                  label="Medical Information"
                  onClick={() => this.updatePage(4, submitForm)}
                  pageNumber={4}
                />
                <PageButton
                  active={currentPage === 5}
                  completed={highestPage > 5}
                  error={this.pagesRefs[5] && this.pagesRefs[5].hasError()}
                  disabled={isSubmitting}
                  label="Permission and Liability Release"
                  onClick={() => this.updatePage(5, submitForm)}
                  pageNumber={5}
                />
              </div>
              <div className="form__group">
                <Page
                  ref={ref => (this.pagesRefs[1] = ref)}
                  errors={status && status.apiErrors}
                  visible={currentPage === 1}
                >
                  <fieldset>
                    <legend>Guest Information</legend>
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="text"
                      name="first_name"
                      label="First Name:"
                    />
                    <FastField readOnly={signOnly} component={Input} type="text" name="last_name" label="Last Name:" />
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="date"
                      name="birth_date"
                      fieldName="birth_date"
                      label="Birth Date:"
                      maxDate={new Date()}
                      minDate={subYears(new Date(), 100)}
                      openToDate={subYears(new Date(), 10)}
                      onChange={birthdate => {
                        if (!birthdate) return
                        setFieldValue('age', differenceInYears(new Date(), birthdate))
                      }}
                    />
                    <FastField readOnly={signOnly} component={Input} type="text" name="age" label="Age:" />
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="select"
                      name="gender"
                      label="Gender:"
                      options={getGendersForSelect()}
                    />
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="text"
                      name="email_address"
                      label="Email Address:"
                    />
                    <FastField readOnly={signOnly} component={Input} type="tel" name="cell_phone" label="Cell Phone:" />
                    <FastField readOnly={signOnly} component={Input} type="tel" name="home_phone" label="Home Phone:" />
                    {values.health_form_type === 'out-of-country' && (
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        type="text"
                        name="passport_number"
                        value={values.passport_number}
                        label="Passport #"
                      />
                    )}
                    {values.health_form_type === 'out-of-country' && (
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        type="date"
                        name="passport_expiry_date"
                        value={values.passport_expiry_date}
                        label="Passport Expiry Date"
                      />
                    )}
                    {values.health_form_type === 'out-of-country' && (
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        type="text"
                        name="passport_country_of_issue"
                        value={values.passport_country_of_issue}
                        label="Country of Issue"
                      />
                    )}
                  </fieldset>
                </Page>
                <Page
                  ref={ref => (this.pagesRefs[2] = ref)}
                  errors={status && status.apiErrors}
                  visible={currentPage === 2}
                >
                  <fieldset>
                    <legend>Mailing Address</legend>
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="text"
                      name="mailing_address"
                      label="Mailing Address:"
                    />
                    <FastField readOnly={signOnly} component={Input} type="text" name="city" label="City:" />
                    <Field
                      readOnly={signOnly}
                      component={Input}
                      type="select"
                      name="province"
                      label="Province:"
                      options={getProvincesForSelect()}
                    />
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="text"
                      name="postal_code"
                      label="Postal Code:"
                    />
                  </fieldset>
                  <fieldset>
                    <legend>Custody Arrangement</legend>
                    <p>
                      The parents/guardians submitting this form on behalf of a minor are those having legal custody of
                      the minor.
                    </p>
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="textarea"
                      name="custody_arrangements_detail"
                      value={values.custody_arrangements_detail}
                      label="Please describe any custody arrangements about which it would be helpful for Young Life to be aware:"
                    />

                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="yes-no"
                      name="custody_arrangements_has_court_order"
                      value={values.custody_arrangements_has_court_order}
                      label="Is there a court order in place that lists certain persons who are not authorized to pick up the camper/guest named on this form from camp?"
                    />
                    {values.custody_arrangements_has_court_order === 'yes' && (
                      <Fragment>
                        <ListField
                          readOnly={signOnly}
                          name="custody_arrangements_authorized_people"
                          label="The following people are authorized:"
                          itemLabel="Authorized person:"
                          addText="Add an authorized person"
                          value={values.custody_arrangements_authorized_people}
                        />
                        <ListField
                          readOnly={signOnly}
                          name="custody_arrangements_unauthorized_people"
                          label="The following people are NOT authorized:"
                          itemLabel="Unauthorized person:"
                          addText="Add an unauthorized person"
                          value={values.custody_arrangements_unauthorized_people}
                        />
                      </Fragment>
                    )}
                  </fieldset>
                  <fieldset>
                    <legend>Emergency Contact Information</legend>
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="text"
                      name="emergency_contact_name"
                      label="Parental/Custodial Guardian Name:"
                    />
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="text"
                      name="emergency_contact_relationship"
                      label="Relationship to Guest:"
                    />
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="tel"
                      name="emergency_contact_phone"
                      label="Contact Phone:"
                    />
                    {values.health_form_type === 'out-of-country' && (
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        type="text"
                        name="emergency_contact_email"
                        value={values.emergency_contact_email}
                        label="Contact Email:"
                      />
                    )}
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="text"
                      name="alternate_emergency_contact_name"
                      label="Emergency Contact Name:"
                    />
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="text"
                      name="alternate_emergency_contact_relationship"
                      label="Relationship to Guest:"
                    />
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="tel"
                      name="alternate_emergency_contact_phone"
                      label="Contact Phone:"
                    />
                    {values.health_form_type === 'out-of-country' && (
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        type="text"
                        name="alternate_emergency_contact_email"
                        value={values.alternate_emergency_contact_email}
                        label="Contact Email:"
                      />
                    )}
                  </fieldset>
                </Page>
                <Page
                  ref={ref => (this.pagesRefs[3] = ref)}
                  errors={status && status.apiErrors}
                  visible={currentPage === 3}
                >
                  <fieldset>
                    <legend>Dietary Restrictions</legend>
                    <p>
                      Please specify dietary preferences and food allergies by selecting the option in the first or
                      second column. If the item is life threatening, please indicate by selecting the option in the
                      third column:
                    </p>
                    <MultipleChoiceGrid
                      readOnly={signOnly}
                      rows={getDietOptionsForForm()}
                      columns={[
                        { label: 'Dietary Preference', name: 'dietary_restrictions_preferences' },
                        { label: 'Allergy', name: 'dietary_restrictions_allergies' },
                        { label: 'Life Threatening Allergy', name: 'dietary_restrictions_life_threatening' },
                      ]}
                      setFieldValue={setFieldValue}
                      values={values}
                      showNA={true}
                      notSure={false}
                    />
                    {(
                      values.dietary_restrictions_preferences +
                      values.dietary_restrictions_allergies +
                      values.dietary_restrictions_life_threatening
                    ).includes('Other Allergies') && (
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        type="text"
                        name="dietary_restrictions_other"
                        label="Please provide more information about your food allergy:"
                      />
                    )}
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="textarea"
                      name="dietary_restrictions_details"
                      label="If an allergy is life-threatening, please indicate if the reaction is a result of ingestion, touch, or airborne:"
                    />
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="textarea"
                      name="allergies_details"
                      label="Please provide any more details about dietary restrictions and/or food allergies:"
                    />
                  </fieldset>
                </Page>
                <Page
                  ref={ref => (this.pagesRefs[4] = ref)}
                  errors={status && status.apiErrors}
                  visible={currentPage === 4}
                >
                  <fieldset>
                    <legend>Medical Information</legend>
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="textarea"
                      name="medications_or_treatments_to_be_continued_at_camp"
                      label="Please list all medications the camper/guest has with them and/or will be taking at camp:"
                    />
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="text"
                      name="family_physician_name"
                      label="Family Physician Name:"
                    />
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="tel"
                      name="family_physician_phone"
                      label="Family Physician Phone:"
                    />
                  </fieldset>
                  <fieldset>
                    <legend>Non-Dietary Allergy Information</legend>
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="yes-no"
                      name="non_dietary_allergies"
                      value={values.non_dietary_allergies}
                      label="Does the camper/guest have any non-dietary allergies?"
                    />
                    {values.non_dietary_allergies === 'yes' && (
                      <>
                        <div className="input-group">
                          <FastField
                            readOnly={signOnly}
                            component={Input}
                            type="yes-no"
                            name="non_dietary_allergies_life_threatening"
                            value={values.non_dietary_allergies_life_threatening}
                            label="Is the allergy life-threatening?"
                          />
                        </div>
                        <div className="input-group">
                          <FastField
                            readOnly={signOnly}
                            component={Input}
                            type="text"
                            name="non_dietary_allergy_description"
                            label="Please indicate allergen, reaction and treatment:"
                          />
                        </div>
                      </>
                    )}
                  </fieldset>
                  <fieldset>
                    <legend>Health Related Restrictions</legend>
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="textarea"
                      name="activities_to_be_limited"
                      label="Does the camper/guest have any medical conditions that could limit participation in an active camp program?"
                    />
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="yes-no"
                      name="carry_inhaler_epipen_emergency_meds"
                      value={values.carry_inhaler_epipen_emergency_meds}
                      label="Does the camper/guest have medication that they are required to carry at all times (ie. inhaler, epi-pen)?"
                    />
                    {values.carry_inhaler_epipen_emergency_meds === 'yes' && (
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        type="textarea"
                        name="carry_inhaler_epipen_emergency_meds_details"
                        value={values.carry_inhaler_epipen_emergency_meds_details}
                        label="Please list and add any additional information."
                      />
                    )}
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="yes-no"
                      name="within_weight_limit"
                      value={values.within_weight_limit}
                      label="There is a weight limitation on the challenge course elements. Participants must be between 60 lb and 270 lb in order to participate. Is the camper/guest being registered within this range?"
                    />
                  </fieldset>
                  <fieldset>
                    <legend>Health Insurance</legend>
                    {values.health_form_type !== 'out-of-country' && (
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        type="yes-no"
                        name="health_insurance_choice"
                        value={values.health_insurance_choice}
                        label="Does the camper/guest have a provincial health care number?"
                      />
                    )}
                    {(values.health_insurance_choice === 'yes' || values.health_form_type === 'out-of-country') && (
                      <div className="input-group">
                        <FastField
                          readOnly={signOnly}
                          component={Input}
                          type="text"
                          name="province_health_insurance_policy_num"
                          label="Provincial Personal Health Number:"
                        />
                        <FastField
                          readOnly={signOnly}
                          component={Input}
                          type="select"
                          name="province_health_insurance_policy_province"
                          label="Province:"
                          options={getProvincesForSelect()}
                          style={{ width: '120px' }}
                        />
                      </div>
                    )}
                    {(values.health_insurance_choice === 'no' || values.health_form_type === 'out-of-country') && (
                      <Fragment>
                        <FastField
                          readOnly={signOnly}
                          component={Input}
                          type="text"
                          name="foreign_medical_co_name"
                          label="Travel Medical Insurance or Extended Health Plan Company:"
                        />
                        <FastField
                          readOnly={signOnly}
                          component={Input}
                          type="text"
                          name="foreign_medical_co_policy_number"
                          label="Travel Policy Number #:"
                        />
                        <FastField
                          readOnly={signOnly}
                          component={Input}
                          type="tel"
                          name="foreign_medical_co_emergency_phone_number"
                          label="Travel Insurance Emergency Phone #:"
                        />
                        <FastField
                          readOnly={signOnly}
                          component={Input}
                          type="tel"
                          name="foreign_medical_co_contact_number"
                          label="Travel Insurance Contact #:"
                        />
                      </Fragment>
                    )}
                  </fieldset>
                  <fieldset>
                    <legend>Medical History</legend>
                    <b>Please indicate past or present when relevant, otherwise leave as N/A:</b>
                    <MultipleChoiceGrid
                      readOnly={signOnly}
                      rows={getMedicalConditionsForForm()}
                      columns={[
                        { label: 'Present', name: 'current_medical_conditions' },
                        { label: 'Past', name: 'past_medical_conditions' },
                      ]}
                      setFieldValue={setFieldValue}
                      values={values}
                      showNA={true}
                      notSure={false}
                    />
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="textarea"
                      name="medical_conditions_detail"
                      label="Please provide relevant detail for our medical practitioner:"
                    />
                  </fieldset>
                  {values.health_form_type !== 'weekend' && (
                    <fieldset>
                      <legend>Immunizations</legend>
                      <b>Please indicate Up-to-date or Never Vaccinated when relevant, otherwise leave as Not Sure:</b>
                      <MultipleChoiceGrid
                        readOnly={signOnly}
                        rows={getImmunizationsForForm()}
                        columns={[
                          { label: 'Up-to-date', name: 'up_to_date_immunizations' },
                          { label: 'Never Vaccinated', name: 'never_vaccinated_immunizations' },
                        ]}
                        setFieldValue={setFieldValue}
                        values={values}
                        showNA={false}
                        notSure={true}
                      />
                    </fieldset>
                  )}
                  <fieldset>
                    <legend>Other Health Concerns</legend>
                    <FastField
                      readOnly={signOnly}
                      component={Input}
                      type="textarea"
                      name="additional_information_for_doctor_or_property"
                      label="Is there any additional medical information we should be aware of?"
                    />
                  </fieldset>
                </Page>
                <Page
                  ref={ref => (this.pagesRefs[5] = ref)}
                  errors={status && status.apiErrors}
                  visible={currentPage === 5}
                >
                  {values.health_form_type === 'out-of-country' && (
                    <fieldset>
                      <legend>Consent of Parent/Guardian to Travel</legend>
                      <p>
                        By signing below, I am giving informed consent to travel and participate in, or to allow my
                        minor child to travel and participate in, a Young Life activity out of Canada, with the
                        understanding that there are inherent risks in any and all aspects of participation.
                      </p>
                      I,
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        name="travel_parent_name"
                        value={values.travel_parent_name}
                        label="Name of Parent/Guardian:"
                        placeholder="Name of Parent/Guardian"
                        className="form__inline-field"
                        style={{ minWidth: '215px' }}
                      />
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        name="travel_email"
                        value={values.travel_email}
                        label="Parent/Guardian email:"
                        placeholder="Parent/Guardian Email"
                        className="form__inline-field"
                      />
                      hereby grant
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        name="travel_child_name"
                        value={values.travel_child_name}
                        label="Name of Child Traveller (minor):"
                        placeholder="Name of Child Traveller (minor)"
                        className="form__inline-field"
                      />
                      permission to travel
                      to
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        name="travel_country"
                        value={values.travel_country}
                        label="Destination Country:"
                        placeholder="Destination Country"
                        className="form__inline-field"
                        style={{ maxWidth: '170px' }}
                      />
                      from
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        type="date"
                        name="travel_departure_date"
                        fieldName="travel_departure_date"
                        value={values.travel_departure_date}
                        label="Departure Date:"
                        placeholder="Departure Date"
                        className="form__inline-field"
                      />
                      to
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        type="date"
                        name="travel_return_date"
                        fieldName="travel_return_date"
                        value={values.travel_return_date}
                        label="Return Date:"
                        placeholder="Return Date"
                        className="form__inline-field"
                      />
                      <Field
                        readOnly={signOnly}
                        component={Input}
                        type="signature"
                        signatureType="travel"
                        signatureEmail={values.email_address}
                        name="travel_signature_attributes"
                      />
                      <h3>Witness:</h3>
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        name="witness_name"
                        value={values.witness_name}
                        label="Name:"
                      />
                      <FastField
                        readOnly={signOnly}
                        component={Input}
                        name="witness_email"
                        value={values.witness_email}
                        label="Email:"
                      />
                      <Field
                        readOnly={signOnly}
                        component={Input}
                        type="signature"
                        signatureType="witness"
                        signatureEmail={values.witness_email}
                        name="witness_signature_attributes"
                      />
                    </fieldset>
                  )}
                  <fieldset>
                    {this.renderConsent(signOnly, values)}
                    {signOnly ? (
                      <Fragment>
                        {signatures
                          .filter(signature => !['travel', 'witness'].includes(signature.attributes['signature-type']))
                          .map((signature, index) => (
                            <div key={index}>
                              <div className="signature__display">{signature.attributes['text']}</div>
                              <p>{formatDate(signature.attributes['updated-at'], 'yyyy-MM-dd h:m aa')}</p>
                            </div>
                          ))}

                        <Notice type="outline">
                          <Field
                            component={Input}
                            type="signature"
                            name="other_signatory_attributes"
                            signatureEmail={values.other_signatory_attributes.email}
                          />
                        </Notice>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Field
                          component={Input}
                          readOnly={signOnly}
                          type="signature"
                          name="primary_signature_attributes"
                          signatureEmail={values.email_address}
                          className="input--inline"
                        />
                        <p className="form__hint">Parent's signature is required if Camper/Guest is under 19</p>
                        <fieldset>
                          <legend>Other Signatories</legend>
                          <ListField
                            name="other_signatories"
                            label="Email addresses of other signatories:"
                            itemLabel="Email address:"
                            addText="Add a signatory"
                            value={values.other_signatories}
                          />
                          <p className="form__hint">
                            Once you click 'Confirm", other signatories will be sent an email with a link to where they
                            can also sign the form. The health form will not be complete until all additional
                            signatories have signed.
                          </p>
                        </fieldset>
                      </Fragment>
                    )}
                  </fieldset>
                </Page>
              </div>

              {status && status.apiErrors && Object.keys(status.apiErrors).length > 0 && (
                <Notice type="error" className="errors--base">
                  <p>Please resolve the following errors</p>
                  <ul>
                    {Object.keys(status.apiErrors).map((key, index) => (
                      <li key={index}>
                        <b>{titleize(key.replace(/_/g, ' ').replace('.', ' - '))}</b>: {status.apiErrors[key]}
                      </li>
                    ))}
                  </ul>
                </Notice>
              )}
              <hr />
              <div className="button-row">
                <div className="button-group">
                  <button
                    type="button"
                    className="inline-button"
                    onClick={() => this.updatePage(currentPage - 1, submitForm)}
                    disabled={isSubmitting || currentPage === 1}
                  >
                    Previous
                  </button>
                </div>
                <div className="button-group">
                  {onConfirm && currentPage < 5 && (
                    <button type="submit" className="inline-button save-button" disabled={isSubmitting}>
                      {submitButton || 'Save'}
                    </button>
                  )}
                  <Link
                    className="gray underlined"
                    onClick={e => this.confirmCancel(e)}
                    to={urlFor(ROUTES.dashboard, { tab: 'campers' })}
                  >
                    Cancel
                  </Link>
                </div>
                <div className="button-group">
                  {currentPage < 5 && (
                    <button
                      type="button"
                      className="inline-button"
                      onClick={() => this.updatePage(currentPage + 1, submitForm)}
                      disabled={isSubmitting || currentPage === 5}
                    >
                      Next
                    </button>
                  )}
                  {onConfirm && currentPage === 5 && (
                    <button
                      type="button"
                      className="inline-button btn success"
                      disabled={isSubmitting}
                      onClick={() => this.handleConfirm(values, { submitForm, setSubmitting, setStatus })}
                    >
                      Confirm
                    </button>
                  )}
                  {!onConfirm && currentPage === 5 && (
                    <button type="submit" className="inline-button save-button" disabled={isSubmitting}>
                      {submitButton || 'Save'}
                    </button>
                  )}
                </div>
              </div>
              <div className="button-row button-row--centered">
                <span className="btn--link" onClick={() => window.scrollTo(0, 0)}>
                  Back to Top
                </span>
              </div>
            </Form>
          )
        }}
      />
    )
  }
}
