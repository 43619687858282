import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { object, string } from 'yup'
import { login } from 'actions'
import { isLoggedIn } from 'selectors'
import { Layout, Input } from 'components'
import { ROUTES } from '../../constants'
import { urlFor } from '../../utils'

const validationSchema = object().shape({
  email: string()
    .required('Required')
    .email(),
  password: string().required('Required'),
})

const mapState = state => ({
  isLoggedIn: isLoggedIn(state),
})

const mapDispatch = {
  login: login,
}

export default connect(
  mapState,
  mapDispatch
)(
  class Login extends Component {
    state = { loginFailed: false }

    render() {
      const { isLoggedIn, login } = this.props
      if (isLoggedIn) {
        return <Redirect to={urlFor(ROUTES['dashboard'], { tab: 'campers' })} />
      }
      return (
        <Layout>
          <div className="container container-thin">
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                login({ email: values.email, password: values.password }).catch(err => {
                  this.setState({ loginFailed: true })
                  setSubmitting(false)
                })
              }}
              className="narrow"
              render={({ isSubmitting }) => (
                <Form className="narrow">
                  {this.state.loginFailed && !isSubmitting && (
                    <div className="flash-error">
                      <p>Your username or password appears to be incorrect, please try again.</p>
                    </div>
                  )}
                  <div className="form__group">
                    <Field component={Input} type="email" className="input-lg" placeholder="Email" name="email" />
                  </div>
                  <div className="form__group">
                    <Field
                      component={Input}
                      type="password"
                      className="input-lg"
                      placeholder="Password"
                      name="password"
                    />
                  </div>
                  <button type="submit">Login</button>
                  <p style={{ textAlign: 'right', marginTop: '5px' }}>
                    <small>
                      <Link to={ROUTES.forgotPassword}>Forgot Password?</Link>
                    </small>
                  </p>
                </Form>
              )}
            />
          </div>
        </Layout>
      )
    }
  }
)
