import React from 'react'
import { getPublicIp } from 'utils'
import cn from 'classnames'
import { get } from 'lodash'
import { Modal } from 'components'

class Initial extends React.Component {
  state = { text: get(this.props, 'field.value.text') || this.props.defaultText || '', isModalOpen: false }

  onSave = async e => {
    const {
      field: { name },
      email,
      form: { setFieldValue, status, setStatus },
      onTextChange,
    } = this.props
    e.preventDefault()
    e.stopPropagation()

    setFieldValue(name, {
      text: this.state.text,
      ip_address: await getPublicIp(),
      email: email,
    })

    if (status) {
      // Reset any server errors pertaining to this field.
      const shortName = name.replace('_attributes', '')
      status.apiErrors[shortName] = undefined
      setStatus(status)
    }
    onTextChange && onTextChange(this.state.text)
    this.closeModal()
  }

  openModal = () => {
    this.setState({ text: this.state.text || this.props.defaultText, isModalOpen: true })
  }

  closeModal = () => {
    this.setState({ isModalOpen: false })
  }

  render() {
    const {
      field: { value },
      readOnly = false,
      optional = false,
    } = this.props
    const { isModalOpen } = this.state
    const isInitialed = value && value.text && value.text.length > 0

    return (
      <div className={cn('initial', { 'initial--incomplete': !isInitialed, 'initial--read-only': readOnly })}>
        <button type="button" className="initial__box" onClick={() => !readOnly && this.openModal()}>
          {isInitialed && <div className="initial__display">{value.text}</div>}
        </button>
        {optional && (
          <p>
            <small>
              <i>Optional - leave blank if you do not agree</i>
            </small>
          </p>
        )}

        <Modal isOpen={isModalOpen} onRequestClose={this.closeModal} contentLabel="Please enter your initials">
          <h3>Please enter your initials</h3>
          <div className="signature__display">{this.state.text}</div>
          <form onSubmit={this.onSave}>
            <input onChange={e => this.setState({ text: e.target.value })} value={this.state.text} />
            <button type="submit">Initial</button>
          </form>
        </Modal>
      </div>
    )
  }
}

export default Initial
