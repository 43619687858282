import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { query } from 'redux-bees'
import { Formik, Form, Field } from 'formik'
import { Link } from 'react-router-dom'

import { Layout, Loading, Input } from 'components'
import { ROUTES } from '../../constants'
import { jsonapiToFormErrors } from 'utils'
import { flashMessage } from 'actions'
import { object, string } from 'yup'
import api from 'api'

const mapDispatch = {
  flash: flashMessage,
}

const validationSchema = object().shape({
  password: string().required('Required'),
  password_confirmation: string()
    .required()
    .test('password-match', 'must match password', function(value) {
      return this.parent.password === value
    }),
})

export default compose(
  connect(
    null,
    mapDispatch
  ),
  query(
    'passwordReset',
    api.getPasswordReset,
    (perform, { match: { params } }) => params.token && perform({ token: params.token })
  )
)(
  class ResetPassword extends Component {
    render() {
      const { passwordReset, status, history, flash } = this.props
      return (
        <Layout>
          <div className="row">
            {status.passwordReset.isLoading && <Loading />}
            {status.passwordReset.hasFailed && (
              <Fragment>
                <p>
                  {Object.values(status.passwordReset.error.errors)
                    .map(error => error.detail)
                    .join(' ')}
                </p>
                <p>
                  <Link to={ROUTES.login} className="btn">
                    Go Back
                  </Link>
                </p>
              </Fragment>
            )}
            {passwordReset && (
              <Formik
                initialValues={{ password: '', password_confirmation: '' }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                  api
                    .updatePassword(
                      { token: passwordReset.attributes['password-reset-token'] },
                      {
                        password_reset: {
                          password: values.password,
                          password_confirmation: values.password_confirmation,
                        }
                      }
                    )
                    .then(() => {
                      flash('Successfully reset your password.', 'success')
                      history.push(ROUTES.login, {})
                    })
                    .catch(err => {
                      jsonapiToFormErrors(values, err, setStatus)
                      setSubmitting(false)
                    })
                }}
                render={({ status }) => (
                  <Form className="narrow">
                    <p>Enter a new password below.</p>
                    <div className="form__group">
                      <Field component={Input} name="password" type="password" label="Password" />
                      <Field component={Input} name="password_confirmation" type="password" label="Confirm Password" />
                    </div>
                    {status && status.apiErrors['base'] && (
                      <div className="errors--base">{status.apiErrors['base']}</div>
                    )}
                    <button type="submit">Reset Password</button>
                    <p>
                      <small>
                        <Link to={ROUTES.login}>Cancel</Link>
                      </small>
                    </p>
                  </Form>
                )}
              />
            )}
          </div>
        </Layout>
      )
    }
  }
)
