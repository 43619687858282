import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { query } from 'redux-bees'

import { login, flashMessage } from 'actions'
import { isLoggedIn } from 'selectors'
import { Layout, FormContainer } from 'components'
import { ROUTES } from '../../constants'
import api from 'api'
import { jsonapiToFormErrors, urlFor } from 'utils'
import CamperForm from './form'

const mapState = state => ({
  isLoggedIn: isLoggedIn(state),
})

const mapDispatch = {
  login: login,
  flash: flashMessage,
}

export default compose(
  connect(
    mapState,
    mapDispatch
  ),
  query('camper', api.getCamper, (perform, props) => perform({ id: props.match.params.id }))
)(
  class EditCamper extends Component {
    onSubmit(values, { setSubmitting, setStatus }) {
      api
        .updateCamper(
          { id: this.props.match.params.id },
          {
            camper: values,
          }
        )
        .then(response => {
          const next = () => {
            const healthForm = this.getNextPage()
            if (!!healthForm) {
              return urlFor(ROUTES.healthForms.edit, { id: healthForm })
            } else {
              return urlFor(ROUTES.dashboard, { tab: 'campers' })
            }
          }
          this.props.flash('Camper Updated')
          this.props.history.push(next())
        })
        .catch(err => {
          jsonapiToFormErrors(values, err, setStatus)
          setSubmitting(false)
        })
    }
    getNextPage() {
      if (this.props.location.search) {
        const params = new URLSearchParams(this.props.location.search)
        if (params.get('health_form')) {
          return params.get('health_form')
        }
      }
      return false
    }
    render() {
      const { camper } = this.props

      return (
        <Layout>
          <FormContainer>
            <h2>Edit Camper</h2>
            {camper && <CamperForm camper={camper} onSubmit={(...args) => this.onSubmit(...args)} />}
          </FormContainer>
        </Layout>
      )
    }
  }
)
