import React, { Fragment } from 'react'
import { Modal } from 'components'
import { getPublicIp } from 'utils'

class Signature extends React.Component {
  state = { text: '', isModalOpen: false }

  onSign = async e => {
    const {
      field: { name },
      signatureEmail,
      signatureType,
      form: { setFieldValue, status, setStatus },
    } = this.props
    e.preventDefault()
    e.stopPropagation()

    setFieldValue(name, {
      id: undefined,
      text: this.state.text,
      ip_address: await getPublicIp(),
      email: signatureEmail,
      signature_type: this.state.signatureType || signatureType,
    })

    if (status) {
      // Reset any server errors pertaining to this field.
      const shortName = name.replace('_attributes', '')
      let newStatus = status
      delete newStatus.apiErrors[`${shortName}_text`]
      delete newStatus.apiErrors[`${shortName}_email`]
      delete newStatus.apiErrors[`${shortName}_ip_address`]
      delete newStatus.apiErrors[`${shortName}_type`]
      setStatus(newStatus)
    }

    this.closeModal()
  }

  openModal(type) {
    this.setState({ isModalOpen: true, signatureType: type })
  }

  closeModal = () => {
    this.setState({ isModalOpen: false })
  }

  render() {
    const {
      field: { value },
      signButtonText = 'Sign Here',
      disabled = false,
    } = this.props
    const { isModalOpen } = this.state
    const isSigned = value && value.text && value.text.length > 0
    return (
      <div className="signature">
        {isSigned && (
          <div className="signature__display" onClick={() => this.openModal()}>
            {value.text}
          </div>
        )}
        {!disabled && (
          <Fragment>
            <button type="button" className={isSigned ? 'link' : ''} onClick={() => this.openModal()}>
              {isSigned ? 'change signature' : signButtonText}
            </button>
            <Modal isOpen={isModalOpen} onRequestClose={this.closeModal} contentLabel="Please enter your name">
              <h3>Please enter your name</h3>
              <div className="signature__display">{this.state.text}</div>
              <form onSubmit={this.onSign}>
                <input onChange={e => this.setState({ text: e.target.value })} value={this.state.text} />
                <button type="submit">{signButtonText}</button>
              </form>
            </Modal>
          </Fragment>
        )}
      </div>
    )
  }
}

export default Signature
