import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import { reducer as beesReducer } from 'redux-bees'
import storage from 'redux-persist/lib/storage'

import flashes from './flashes'
import authentication from './authentication'

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['authentication'],
}

const appReducer = combineReducers({
  flashes,
  authentication,
  bees: beesReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    Object.keys(state).forEach(key => {
      storage.removeItem(`persist:${key}`)
    })
    state = undefined
  }

  return appReducer(state, action)
}

export default () => persistReducer(rootPersistConfig, rootReducer)
