import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { flashMessage } from 'actions'
import { Layout, HealthForm, FormContainer } from 'components'
import { ROUTES } from '../../../constants'
import api from 'api'
import { jsonapiToFormErrors } from 'utils'

const mapState = state => ({
  // authInfo: getAuth(state),
})

const mapDispatch = {
  flash: flashMessage,
}

export default compose(
  connect(
    mapState,
    mapDispatch
  )
)(
  class NewHealthForm extends Component {
    onSubmit(values, { setSubmitting, setStatus }) {
      api
        .createHealthForm({ id: this.props.match.params.id }, { health_form: values })
        .then(response => {
          setSubmitting(false)
          this.props.history.push(ROUTES.public.healthForms.created)
        })
        .catch(err => {
          jsonapiToFormErrors(values, err, setStatus)
          setSubmitting(false)
        })
    }
    render() {
      return (
        <Layout>
          <FormContainer>
            <HealthForm
              validateOnPageChange={false}
              campEventSelect={true}
              onSubmit={(...args) => this.onSubmit(...args)}
              submitButton={'Confirm'}
              type={this.props.match.params.type}
              history={this.props.history}
            />
          </FormContainer>
        </Layout>
      )
    }
  }
)
