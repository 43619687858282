import React, { Component } from 'react'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { query } from 'redux-bees'
import { Formik, Form, Field } from 'formik'
import { object, string } from 'yup'
import { Layout, FormContainer, Input } from 'components'
import { ROUTES } from '../../constants'
import { loginAfterSignup } from 'actions'
import { jsonapiToFormErrors, isLeaders, urlFor } from 'utils'
import api from 'api'

const validationSchema = object().shape({
  first_name: string().required(),
  last_name: string().required(),
  email: string()
    .required()
    .email(),
  password: string().required(),
  password_confirmation: string()
    .required()
    .test('password-match', 'must match password', function(value) {
      return this.parent.password === value
    }),
})

const SignupForm = ({ onSubmitSuccess, areas }) => (
  <Formik
    initialValues={{
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password_confirmation: '',
    }}
    validationSchema={validationSchema}
    onSubmit={({ camp_area, ...values }, { setSubmitting, setStatus }) => {
      api
        .signup({ user: { camp_area_id: camp_area, ...values } })
        .then(onSubmitSuccess)
        .catch(err => {
          jsonapiToFormErrors(values, err, setStatus)
          setSubmitting(false)
        })
    }}
    render={({ values, status }) => (
      <Form className="form">
        <h2>Sign Up</h2>
        <fieldset>
          <legend>Step 1: Choose your Young Life Area</legend>
          <Field
            component={Input}
            type="select"
            name="camp_area"
            value={values.camp_area}
            label="Choose your Young Life Area"
            options={
              areas
                ? areas.map(area => ({
                    label: [area.attributes.name, area.attributes.description].filter(Boolean).join(' - '),
                    value: area.id,
                  }))
                : []
            }
          />
        </fieldset>
        <fieldset>
          <legend>Step 2: Create a Parent/Guardian Account</legend>
          <div className="form__group">
            <Field
              component={Input}
              name="first_name"
              placeholder="First Name"
              label={isLeaders ? 'First Name' : 'Parent/Guardian First Name'}
            />
            <Field
              component={Input}
              name="last_name"
              placeholder="Last Name"
              label={isLeaders ? 'Last Name' : 'Parent/Guardian Last Name'}
            />
            <Field
              component={Input}
              name="email"
              placeholder="Email"
              label={isLeaders ? 'Email' : 'Parent/Guardian Email'}
            />
            <Field component={Input} name="password" type="password" placeholder="Password" label="Password" />
            <Field
              component={Input}
              name="password_confirmation"
              type="password"
              placeholder="Confirm Password"
              label="Confirm Password"
            />
          </div>
          {status && status.apiErrors['base'] && <div className="errors--base">{status.apiErrors['base']}</div>}
          <button type="submit">Sign Up</button>
          <Link to={'/'}>Cancel</Link>
        </fieldset>
      </Form>
    )}
  />
)

const mapDispatch = {
  login: loginAfterSignup,
}

const areaQuery = isLeaders
  ? query('areas', api.getAreas, (perform, _props) => perform({ leaders_only: true }))
  : query('areas', api.getAreas)
export default compose(
  connect(null, mapDispatch),
  areaQuery
)(
  class Signup extends Component {
    onSubmitSuccess(response) {
      const user = response.body.data
      this.props.login({
        token: user.attributes['authentication-token'],
        email: user.attributes.email,
        firstName: user.attributes['first-name'],
        userId: user.id,
      })
      this.props.history.push(urlFor(ROUTES['dashboard'], { tab: 'campers' }))
    }
    render() {
      const { areas } = this.props

      return (
        <Layout>
          <FormContainer>
            <SignupForm areas={areas} onSubmitSuccess={response => this.onSubmitSuccess(response)} />
          </FormContainer>
        </Layout>
      )
    }
  }
)
