import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAuth } from 'selectors'
import { compose } from 'redux'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { query, getRelationship } from 'redux-bees'
import { Layout, Loading } from 'components'
import api from 'api'
import DashboardAreaCamps from './area-camps'
import IncompleteHealthForms from './incomplete-health-forms'
import DashboardPayments from './payments'
import DashboardCampers from './campers'
import { isLeaders, urlFor } from 'utils'
import { ROUTES } from '../../constants'

const tabs = {
  'incomplete-health-forms': {
    url: urlFor(ROUTES.dashboard, { tab: 'incomplete-health-forms' }),
    title: () => (
      <span>
        <FontAwesomeIcon icon={['far', 'exclamation-circle']} />
        {` `}Finalize your health form(s)
      </span>
    ),
    hidden: dashboard => dashboard.getIncompleteHealthForms().length === 0,
    className: 'warning',
  },
  campers: {
    url: urlFor(ROUTES.dashboard, { tab: 'campers' }),
    title: `Add ${isLeaders ? 'Leader' : 'Teen'}(s) to your family account`,
  },
  camps: {
    url: urlFor(ROUTES.dashboard, { tab: 'camps' }),
    title: `Register ${isLeaders ? '' : 'teens '}for camp`,
  },
  payments: {
    url: urlFor(ROUTES.dashboard, { tab: 'payments' }),
    title: 'Pay for camp',
  },
}

const mapState = (state, props) => ({
  authInfo: getAuth(state),
  ylCamp: areaCamp => getRelationship(state, areaCamp, 'yl-camp'),
  area: user => getRelationship(state, user, 'camp-area'),
  areaCamp: registration => getRelationship(state, registration, 'area-camp'),
  registration: payment => getRelationship(state, payment, 'registration'),
  healthForm: registration => getRelationship(state, registration, 'health-form'),
  getRegistrations: camper => getRelationship(state, camper, 'registrations'),
  registrationPayments: registration => getRelationship(state, registration, 'payments'),
})

export default compose(
  connect(mapState),
  query('areaCamps', api.getAreaCamps, (perform, props) => perform({ active: true, include: 'yl_camp' })),
  query('user', api.getUser, (perform, props) => perform({ id: props.authInfo.userId, include: 'camp_area' })),
  query('payments', api.getPayments, (perform, props) => perform({ 'include[]': ['registration.area_camp'] })),
  query('campers', api.getCampers, (perform, props) =>
    perform({ 'include[]': ['registrations.area_camp', 'registrations.health_form', 'registrations.payments'] })
  )
)(
  class Dashboard extends Component {
    render() {
      const {
        match: {
          params: { tab: currentTab = 'campers' },
        },
      } = this.props
      const visibleTabs = Object.entries(tabs).reduce((visibleTabs, [key, tab]) => {
        if (!tab.hidden || !tab.hidden(this)) visibleTabs[key] = tab
        return visibleTabs
      }, {})

      return (
        <Layout>
          <div className="container">
            <h1>Dashboard</h1>
            {isLeaders ? (
              <ul className="list">
                <li>Step 1: Create a leader record to add yourself to your family account.</li>
                <li>Step 2: Click Register Now to register for camp.</li>
                <li>Step 3: Pay for camp.</li>
              </ul>
            ) : (
              <ul className="list">
                <li>Step 1: Add your teen to your family account.</li>
                <li>Step 2: Register your teen for camp.</li>
                <li>Step 3: Pay for camp.</li>
              </ul>
            )}

            <VerticalTabs tabs={visibleTabs} currentTab={currentTab} renderContent={this.renderTab.bind(this)} />
          </div>
        </Layout>
      )
    }

    renderTab(key) {
      const {
        areaCamps,
        ylCamp,
        area,
        user,
        payments,
        campers,
        areaCamp,
        getRegistrations,
        registration,
        healthForm,
        registrationPayments,
      } = this.props

      switch (key) {
        case 'incomplete-health-forms':
          return <IncompleteHealthForms incompleteHealthForms={this.getIncompleteHealthForms()} />
        case 'campers':
          return campers ? (
            <DashboardCampers
              campers={campers}
              registrations={getRegistrations}
              healthForm={healthForm}
              payments={registrationPayments}
              areaCamp={areaCamp}
            />
          ) : (
            <Loading />
          )
        case 'camps':
          return areaCamps && user ? (
            <DashboardAreaCamps areaCamps={areaCamps} user={user} area={area} ylCamp={ylCamp} />
          ) : (
            <Loading />
          )
        case 'payments':
          return payments && user ? (
            <Fragment>
              <DashboardPayments payments={payments} getRegistration={registration} getAreaCamp={areaCamp} />
            </Fragment>
          ) : (
            <Loading />
          )
        default:
          return <p>No tab content defined</p>
      }
    }

    getIncompleteHealthForms() {
      const { registrations, healthForm } = this.props
      if (!registrations) return []

      return registrations.reduce((incompleteForms, registration) => {
        const _healthForm = healthForm(registration)
        if (!_healthForm || _healthForm.attributes.status === 'Complete') return incompleteForms
        _healthForm.registration = registration
        incompleteForms.push(_healthForm)
        return incompleteForms
      }, [])
    }
  }
)

const VerticalTabs = ({ currentTab, tabs, renderContent }) => (
  <div className="vertical-tabs">
    <ul className="vertical-tabs__tabs">
      {Object.entries(tabs).map(([key, tab]) => (
        <li key={key}>
          <Link
            className={cn('vertical-tabs__tab', { 'vertical-tabs__tab--active': currentTab === key })}
            to={tab.url}
            key={key}
          >
            {typeof tab.title === 'function' ? tab.title() : tab.title}
          </Link>
        </li>
      ))}
    </ul>
    <div className="vertical-tabs__content">
      {Object.entries(tabs).map(([key, tab]) => (
        <Fragment key={key}>
          <Link
            to={tab.url}
            className={cn('vertical-tabs__accordian-title', {
              'vertical-tabs__accordian-title--active': currentTab === key,
            })}
          >
            {typeof tab.title === 'function' ? tab.title() : tab.title}
          </Link>
          <div
            className={cn('vertical-tabs__tab-content', { 'vertical-tabs__tab-content--active': currentTab === key })}
          >
            {renderContent(key)}
          </div>
        </Fragment>
      ))}
    </div>
  </div>
)
