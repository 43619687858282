import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Layout } from 'components'
import { query } from 'redux-bees'
import { compose } from 'redux'
import { getAuth } from 'selectors'
import { Link } from 'react-router-dom'
import api from 'api'
import { formatDate } from 'utils'

const mapState = (state, props) => ({
  authInfo: getAuth(state),
})

export default compose(
  connect(mapState),
  query('payment', api.getPayment, (perform, props) => perform({ id: props.match.params.id }))
)(
  class ViewPayment extends Component {
    render() {
      const { payment } = this.props
      return (
        <Layout>
          <div className="flex-boxes">
            <div className="flex-box">
              {payment && (
                <div className="content">
                  <h2>This payment was received by our office and added to your account</h2>
                  <h2>Payment Details:</h2>
                  <dl>
                    <dt>Type</dt>
                    <dd>{payment.attributes['payment-type']}</dd>
                    <dt>Amount</dt>
                    <dd>${payment.attributes['amount']}</dd>
                    <dt>Paid</dt>
                    <dd>{formatDate(payment.attributes['updated-at'], 'yyyy-MM-dd')}</dd>
                  </dl>
                  <Link className="btn default" to="/dashboard">
                    Return to the dashboard
                  </Link>
                </div>
              )}
            </div>
          </div>
        </Layout>
      )
    }
  }
)
