import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'

const mapState = state => ({
  messages: state.flashes.messages,
})
export default connect(mapState)(({ messages }) => (
  <Fragment>
    {messages.map((message, index) => (
      <span className={cn(`flash`, `flash-${message.kind}`)} key={index}>
        {message.message}
      </span>
    ))}
  </Fragment>
))
