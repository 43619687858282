import React, { Component, Fragment } from 'react'
import { Formik, Form, Field } from 'formik'
import { Link } from 'react-router-dom'
import { Layout } from 'components'
import { ROUTES } from '../../constants'
import api from 'api'

export default class ForgotPassword extends Component {
  state = { submitted: false }
  render() {
    return (
      <Layout>
        <div className="row">
          {this.state.submitted ? (
            <Fragment>
              <p>
                If your email address exists in our database, you will receive an email with instructions for how to
                confirm your email address in a few minutes.
              </p>
              <p>
                <Link to={ROUTES.login} className="btn">
                  Ok
                </Link>
              </p>
            </Fragment>
          ) : (
            <Formik
              initialValues={{ email: '' }}
              validate={values => {
                let errors = {}
                if (!values.email) {
                  errors.email = 'Required'
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                  errors.email = 'Invalid email address'
                }
                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {
                api.requestPasswordReset({ email: values.email }).finally(() => {
                  this.setState({ submitted: true })
                  setSubmitting(false)
                })
              }}
              render={() => (
                <Form className="narrow">
                  <p>Enter your email below, and we will send you a link to reset your password.</p>
                  <div className="form__group">
                    <Field type="email" className="form-control input-lg" placeholder="Email" name="email" />
                  </div>
                  <button type="submit">Reset Password</button>
                  <p>
                    <small>
                      <Link to={ROUTES.login}>Cancel</Link>
                    </small>
                  </p>
                </Form>
              )}
            />
          )}
        </div>
      </Layout>
    )
  }
}
