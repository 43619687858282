import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { query, getRelationship } from 'redux-bees'

import { login, flashMessage } from 'actions'
import { isLoggedIn } from 'selectors'
import { Layout, FormContainer, HealthForm, Notice } from 'components'
import { ROUTES } from '../../../constants'
import api from 'api'
import { jsonapiToFormErrors, urlFor, dashToUnderscoreObject } from 'utils'

const mapState = state => ({
  isLoggedIn: isLoggedIn(state),
  getSignatures: form => getRelationship(state, form, 'signatures'),
  getTravelSignature: form => getRelationship(state, form, 'travel-signature'),
  getWitnessSignature: form => getRelationship(state, form, 'witness-signature'),
  getInitials: healthForm => getRelationship(state, healthForm, 'initials'),
})

const mapDispatch = {
  login: login,
  flash: flashMessage,
}

export default compose(
  connect(
    mapState,
    mapDispatch
  ),
  query('healthForm', api.getHealthFormForOtherSignatories, (perform, { match: { params: { token } } }) =>
    perform({
      token: token,
      'include[]': 'initials,signatures,travel_signature,witness_signature',
    })
  )
)(
  class SignHealthForm extends Component {
    state = { email: this.getEmail() }

    onSubmit(values, { setSubmitting, setStatus }) {
      api
        .signHealthForm(
          { token: this.props.match.params.token },
          {
            signature: {
              text: values.other_signatory_attributes.text,
              ip_address: values.other_signatory_attributes.ip_address,
              email: this.state.email,
            },
          }
        )
        .then(_response => {
          this.props.flash('Signed')
          this.props.history.push(urlFor(ROUTES.public.healthForms.signSuccess))
        })
        .catch(err => {
          jsonapiToFormErrors(values, err, setStatus)
        })
        .finally(() => setSubmitting(false))
    }
    getEmail() {
      const params = new URLSearchParams(this.props.location.search)
      const email = params.get('email') || localStorage.getItem('campSignatureEmail')
      localStorage.setItem('campSignatureEmail', email)
      return email
    }
    render() {
      const { healthForm, getSignatures, getTravelSignature, getWitnessSignature, getInitials } = this.props
      const signatures = getSignatures(healthForm)
      const travelSignature = getTravelSignature(healthForm)
      const witnessSignature = getWitnessSignature(healthForm)

      return (
        <Layout>
          <FormContainer
            before={
              <Notice>
                <p>
                  As a requested signatory, your signature is needed on the document below. Please review the
                  information entered below, and sign at the bottom.
                </p>
              </Notice>
            }
          >
            {healthForm && (
              <HealthForm
                validateOnPageChange={false}
                healthForm={healthForm}
                signatures={signatures}
                travelSignature={
                  travelSignature && {
                    id: travelSignature.id,
                    ...dashToUnderscoreObject(travelSignature.attributes),
                  }
                }
                travelWitnessSignature={
                  witnessSignature && {
                    id: witnessSignature.id,
                    ...dashToUnderscoreObject(witnessSignature.attributes),
                  }
                }
                initials={getInitials(healthForm).reduce((initialsObject, initial) => {
                  initialsObject[initial.attributes['initial-type']] = {
                    id: initial.id,
                    ...dashToUnderscoreObject(initial.attributes),
                  }
                  return initialsObject
                }, {})}
                signOnly={true}
                signEmail={this.state.email}
                onSubmit={(...args) => this.onSubmit(...args)}
                history={this.props.history}
              />
            )}
          </FormContainer>
        </Layout>
      )
    }
  }
)
