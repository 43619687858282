import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { query } from 'redux-bees'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import api from 'api'
import { Layout, FormContainer } from 'components'
import { getAuth } from 'selectors'
import { ROUTES } from '../../constants'
import { formatDate, isCreditCardPayment, toCurrency, urlFor } from 'utils'

const mapState = (state, props) => ({
  authInfo: getAuth(state),
})

export default compose(
  connect(mapState),
  query('payment', api.getPayment, (perform, props) => perform({ id: props.match.params.id }))
)(
  class PaymentSuccess extends Component {
    render() {
      const { payment } = this.props

      return (
        <Layout>
          {payment && (
            <FormContainer>
              {isCreditCardPayment(payment) ? (
                <Fragment>
                  {payment.attributes['cc-status'] === 'complete' ? (
                    <h2>Thank you for your payment!</h2>
                  ) : payment.attributes['cc-status'] === 'refunded' ? (
                    <h2>This payment has been refunded</h2>
                  ) : (
                    <h2>Unfortunately, your payment could not be processed</h2>
                  )}
                  <h3>Payment Details:</h3>
                  <dl>
                    <dt>Status</dt>
                    <dd>{payment.attributes['cc-status']}</dd>
                    <dt>Transaction #</dt>
                    <dd>{payment.attributes['cc-identifier']}</dd>
                    <dt>Response Code</dt>
                    <dd>{payment.attributes['cc-response-code']}</dd>
                  </dl>
                  {payment.attributes['paid'] && (
                    <Fragment>
                      <p>
                        You should receive an email shortly containing these details. Please keep the email for your
                        records.
                      </p>
                      <p>
                        <a
                          className="btn info"
                          href="mailto:camp-refunds@younglife.ca?subject=Refund requested for registration {{registration.id}}"
                        >
                          Request Refund
                        </a>
                      </p>
                    </Fragment>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <h2>This payment was received by our office and added to your account</h2>
                  <h2>Payment Details:</h2>
                  <dl>
                    <dt>Type</dt>
                    <dd>{payment.attributes['payment_type']}</dd>
                    <dt>Amount</dt>
                    <dd>{toCurrency(payment.attributes['amount'])}</dd>
                    <dt>Paid</dt>
                    <dd>{formatDate(payment.attributes['updated-at'], 'yyyy-MM-dd')}</dd>
                  </dl>
                </Fragment>
              )}
              <Link className="btn" to={urlFor(ROUTES.dashboard, { tab: 'campers' })}>
                Return to Dashboard
              </Link>
            </FormContainer>
          )}
        </Layout>
      )
    }
  }
)
