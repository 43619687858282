import React, { Component } from 'react'
import { Header, FlashMessages, Footer } from 'components'

export default class Layout extends Component {
  render() {
    const { children, headerBackground, headerTitle, headerSize } = this.props
    return (
      <div>
        <Header background={headerBackground} title={headerTitle} size={headerSize} />

        <section id="main">
          <FlashMessages />

          {children}
        </section>
        <Footer />
      </div>
    )
  }
}
