const DEFAULT_STATE = {
  userId: undefined,
  email: undefined,
  token: undefined,
  firstName: undefined,
}

export default (state = DEFAULT_STATE, { type, payload }) => {
  switch (type) {
    case 'USER_LOGIN':
      const { userId, email, token, firstName } = payload
      return {
        userId,
        email,
        token,
        firstName,
      }
    case 'USER_LOGOUT':
      return DEFAULT_STATE
    default:
      return state
  }
}
