import React from 'react'
import { Link } from 'react-router-dom'
import { Layout, FormContainer } from 'components'
import { ROUTES } from '../../../constants'

export default () => (
  <Layout>
    <FormContainer>
      <h2>Your health form has been submitted successfully</h2>
      <p>
        If you need to fill out additional forms, <Link to={ROUTES.public.healthForms.landing}>Click here</Link> to
        return to the list of forms available.
      </p>
    </FormContainer>
  </Layout>
)
