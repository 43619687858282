import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Layout } from 'components'
import { query, getRelationship } from 'redux-bees'
import { compose } from 'redux'
import { getAuth } from 'selectors'
import { Link } from 'react-router-dom'
import api from 'api'
import { ROUTES } from '../../constants'
import { urlFor, formatDate } from 'utils'

const mapState = (state, props) => ({
  authInfo: getAuth(state),
  campers: user => getRelationship(state, user, 'campers'),
  getRegistrations: camper => getRelationship(state, camper, 'registrations'),
  areaCamp: registration => getRelationship(state, registration, 'area-camp'),
  ylCamp: areaCamp => getRelationship(state, areaCamp, 'yl-camp'),
})

export default compose(
  connect(mapState),
  query('user', api.getPublicUser, (perform, props) =>
    perform({ token: props.match.params.token, 'include[]': 'campers.registrations.area_camp.yl_camp' })
  )
)(
  class PublicUser extends Component {
    render() {
      const { user, getRegistrations, areaCamp, campers, ylCamp } = this.props
      return (
        <Layout>
          <div className="container">
            {user && (
              <Fragment>
                <h2>
                  Welcome to the public page for {`${user.attributes['first-name']} ${user.attributes['last-name']}`}
                </h2>
                <h2>Outstanding Registrations</h2>
                {campers(user) ? (
                  campers(user).map(
                    camper =>
                      getRegistrations(camper) &&
                      getRegistrations(camper).map(registration => {
                        return (
                          <Fragment key={registration.id}>
                            <div className="card">
                              {areaCamp(registration) && ylCamp(areaCamp(registration)) && (
                                <div className="card-header">
                                  <b>{`${areaCamp(registration).attributes['name']} `}</b>
                                  <span>
                                    {`${formatDate(
                                      ylCamp(areaCamp(registration)).attributes['start-date'],
                                      'yyyy-MM-dd'
                                    )} - ${formatDate(
                                      ylCamp(areaCamp(registration)).attributes['end-date'],
                                      'yyyy-MM-dd'
                                    )}`}
                                  </span>
                                </div>
                              )}
                              <div className="card-copy">
                                <dl>
                                  <dt>Camper Name</dt>
                                  <dt>{`${camper.attributes['first-name']} ${camper.attributes['last-name']}`}</dt>
                                  <dt>Type</dt>
                                  <dd>{camper.attributes['camper-type']}</dd>
                                </dl>
                              </div>
                              <div className="card-footer">
                                <Link
                                  to={urlFor(ROUTES.payments.new, {
                                    id: registration.attributes['token'],
                                  })}
                                  className="btn inline info"
                                >
                                  <span className="hide-mobile">Make Payment/Share Payment Page</span>
                                  <span className="hide-desktop">Pay</span>
                                </Link>
                              </div>
                            </div>
                            <br />
                          </Fragment>
                        )
                      })
                  )
                ) : (
                  <div>No registrations to show</div>
                )}
              </Fragment>
            )}
          </div>
        </Layout>
      )
    }
  }
)
