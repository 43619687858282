import { setLocale } from 'yup'

setLocale({
  mixed: {
    required: 'Required',
  },
  string: {
    email: 'must be a valid email',
  },
})
