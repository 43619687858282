import React, { Fragment } from 'react'
import { includes } from 'lodash'

class MultipleChoiceGrid extends React.Component {
  getChecked(row, col) {
    return includes(this.props.values[col.name], row)
  }

  select(row, colToSelect) {
    const { setFieldValue, values, columns } = this.props

    columns.forEach(col => {
      let newValue = values[col.name] || []
      newValue = newValue.filter(val => val !== row)
      if (col.name === colToSelect.name) {
        newValue.push(row)
      }
      setFieldValue(col.name, newValue)
    })
  }

  clear(row) {
    const { setFieldValue, values, columns } = this.props
    columns.forEach(col => {
      let newValue = values[col.name] || []
      newValue = newValue.filter(val => val !== row)
      setFieldValue(col.name, newValue)
    })
  }

  render() {
    const { rows, columns, readOnly, showNA, notSure } = this.props

    return (
      <Fragment>
        <table className="option-list">
          <thead>
            <tr>
              <th />
              {columns.map((col, index) => (
                <th key={index}>{col.label}</th>
              ))}
              {showNA && <th>N/A</th>}
              {notSure && <th>Not Sure</th>}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td>{row}</td>
                {columns.map((col, index) => (
                  <td key={index}>
                    <input
                      type="radio"
                      name={row}
                      value={col.label}
                      disabled={readOnly}
                      checked={this.getChecked(row, col)}
                      onChange={e => this.select(row, col)}
                    />
                  </td>
                ))}
                {showNA && (
                  <td key={index}>
                    <input
                      type="radio"
                      name={row}
                      value=""
                      disabled={readOnly}
                      checked={!columns.some(col => this.getChecked(row, col))}
                      onChange={() => this.clear(row)}
                    />
                  </td>
                )}
                {notSure && (
                  <td key={index}>
                    <input
                      type="radio"
                      name={row}
                      value=""
                      disabled={readOnly}
                      checked={!columns.some(col => this.getChecked(row, col))}
                      onChange={() => this.clear(row)}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    )
  }
}

export default MultipleChoiceGrid
