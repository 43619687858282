import React, { Fragment } from 'react'
import { Route, withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { ROUTES } from '../../constants'
import { isLoggedIn } from 'selectors'

const mapState = state => ({
  isLoggedIn: isLoggedIn(state),
})

export default withRouter(
  connect(mapState)(({ component: Component, isLoggedIn, ...rest }) => (
    <Route
      {...rest}
      render={props => {
        return isLoggedIn ? (
          <Fragment>
            <Component {...props} />
          </Fragment>
        ) : (
          <Redirect
            to={{
              pathname: ROUTES['login'],
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  ))
)
